<button class="button"
    [ngClass]="{
        'button-solid': isSolid,
        'button-regular': isRegular,
        'button-text': isText,
        'button-width': !isIcon,
        'button-disabled': isDisabled,
        'button-icon-trash': isIcon == 'trash',
    }">
    <span *ngIf="isRegularForMonth" class="button-icon" (click)="onPreviousMonth($event)">
        <mat-icon *ngIf="isDoubleIcon" svgIcon="arrow-left"></mat-icon>
    </span>
    <span [ngClass]="{ 'button-text-underline': isTextUnderline && isText }" *ngIf="titleButton">{{ titleButton }}</span>
    <span *ngIf="isRegularForMonth" class="button-icon" (click)="onNextMonth($event)">
      <mat-icon *ngIf="isDoubleIcon" svgIcon="arrow-right"></mat-icon>
  </span>
    <span
        *ngIf="isIcon"
        class="button-icon"
        [ngClass]="{
            'button-icon-margin': titleButton,
        }">
        <mat-icon *ngIf="isIcon == 'arrow'" svgIcon="arrow"></mat-icon>
        <mat-icon *ngIf="isIcon == 'trash' && isRegular" svgIcon="trash"></mat-icon>
    </span>
</button>
