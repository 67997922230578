import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AttachmentApi {
  apiUrl = '/skrndapp'
  headers: HttpHeaders;
  token: string|null;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('view_auth_token');
    this.headers = new HttpHeaders().append('Authorization', `Basic ${this.token}`)
  }

  getAttachments(parentType, parentId) {
    return this.http.get(`${this.apiUrl}/attachments`, {
      params: {
        parentType: parentType,
        parentId: parentId,
        page: 0,
        size: 40,
        sortBy: 'id',
        sortDirection: 'ASC'
      },
      headers: this.headers
    })
  }

  setAttachments(parentType, parentId, content: FormData) {
    content.append('parentType', parentType);
    content.append('parentId', parentId);
    return this.http.post(`${this.apiUrl}/attachment`, content
  )
  }

  downloadAttachmentById(id: any): Observable<Blob>  {
    return this.http.get(`${this.apiUrl}/attachment/${id}/file`, {
      responseType: 'blob',
      headers: this.headers
    })
   }

   deleteAttachmentById(id: any) {
    return this.http.delete(`${this.apiUrl}/attachment/${id}`, {
      headers: this.headers
    })
   }
}
