import {NgModule} from "@angular/core";
import {NotificationComponent} from "./notification.component";
import {NotificationContainerComponent} from "./notification-container/notification-container.component";
import {CommonModule} from "@angular/common";

@NgModule({
  declarations: [NotificationComponent, NotificationContainerComponent],
  exports: [NotificationComponent, NotificationContainerComponent],
  imports: [ CommonModule
  ]
})
export class NotificationModule {}
