<div class="dropdown" [ngClass]="{'text': type == 'text', 'button': type == 'button','disabled': isDisabled}">
    <label class="dropdown-block__label text-14 text-600" *ngIf="type == 'text'">{{ label }}</label>
    <div class="dropdown-block" (click)="isResult = !isResult">
        <label class="dropdown-block__placeholder" [ngClass]="{
            'active': placeholder && !isResult && type == 'text',
            'c-white-color': type == 'button'
        }">
            <ng-container *ngIf="isMultiple">
                <div *ngIf="listDropdownPlaceholder.length == 0">{{ placeholder }}</div>
                <ng-container *ngIf="listDropdownPlaceholder.length !== 0">
                    <div *ngFor="let place of listDropdownPlaceholder">
                        {{ place.name }}
                    </div>
                </ng-container>
            </ng-container>
            <div *ngIf="!isMultiple">
                {{ placeholder }}
            </div>
        </label>
        <mat-icon class="dropdown-block__icon" [ngClass]="{'active': isResult}"  *ngIf="true" svgIcon="arrow-right"></mat-icon>
    </div>
    <div class="dropdown-list" *ngIf="isResult">
        <div class="dropdown-list__item"   *ngFor="let item of listDropdown" (click)="selectItem(item); $event.stopPropagation()">
            <div class="dropdown-text" >{{ item.name }}</div>
            <div class="dropdown-checkbox" *ngIf="isMultiple" (click)="selectItem(item); $event.stopPropagation()">
              <mat-icon class="dropdown-block__icon" *ngIf="!isItemSelected(item)" svgIcon="not-select-checkbox"></mat-icon>
              <mat-icon class="dropdown-block__icon" *ngIf="isItemSelected(item)" svgIcon="select-checkbox"></mat-icon>
            </div>
          </div>
    </div>
</div>
