import { ChangeDetectionStrategy, Component } from '@angular/core';
import {TaskTrackerTable} from "../task-tracker/task-tracker-table-2.component";

@Component({
  selector: 'app-my-task',
  standalone: true,
  imports: [
    TaskTrackerTable
  ],
  templateUrl: './my-task.component.html',
  styleUrl: './my-task.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyTaskComponent {

}
