import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ProjectApi} from "../../shared/api/project.api";
import {MainProjectLayoutService} from "../../pages/main-project-layout/main-project-layout.service";
import {Router} from "@angular/router";
import { KeycloakService } from 'keycloak-angular';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {CurrentUserService} from "../../shared/services/current-user.service";
import {UpdateProjectListService} from "../../shared/services/update-project-list.service";
import {VersionHistoryService} from "../../shared/services/version-history.service";

@Component({
  selector: 'app-navigation-menu-sidebar',
  templateUrl: './navigation-menu-sidebar.component.html',
  styleUrl: './navigation-menu-sidebar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationMenuSidebarComponent  implements OnInit, AfterViewInit, OnDestroy{
  constructor(
    private projectApi : ProjectApi,
    private changeDetectionRef: ChangeDetectorRef,
    private mainService: MainProjectLayoutService,
    private router: Router,
    private keycloak: KeycloakService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private currentUserService: CurrentUserService,
    private projectListUpdateService: UpdateProjectListService,
    private versionHistoryService: VersionHistoryService
  ) {
  }
  selectedProjectId: number | null = null;
  projectList: any;
  currentUser: any = {
    firstName: '',
    lastName: ''
  };
  isVersionHistoryOpen = false;

  ngOnInit() {
    this.getProjectList();
    this.getCurrentUser();
    this.projectListUpdateService.projectListUpdate.subscribe((res) => {
      if(res) {
        this.getProjectList()
        this.selectedProjectId = res;
      }
    })
    this.versionHistoryService.isVersionHistoryPageOpen.subscribe((res) => {
        this.isVersionHistoryOpen = res;
        this.changeDetectionRef.markForCheck()
    })
    this.matIconRegistry.addSvgIcon(
      'exit',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/exit.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'plus',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/plus-circle.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'setting',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/settings.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'close-sidebar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/close-sidebar.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'folder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/folder.svg')
    );
  }
  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.currentUserService.currentUser.unsubscribe();
    this.projectListUpdateService.projectListUpdate.unsubscribe();
  }

  getProjectList() {
    this.projectApi.getProjectList()
      .subscribe((res:any) => {
        this.projectList = res.content;
        this.changeDetectionRef.markForCheck();
      })

  }
  updateId(id) {
    this.mainService.id.next(id)
    this.selectedProjectId = id;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`project/${id}`])});
  }
  logout() {
    localStorage.removeItem('view_auth_token')
    this.keycloak.logout();
  }
  getCurrentUser() {
    this.currentUserService.currentUser.subscribe((user) => {
      this.currentUser = user;
    })
  }

}
