<div class="notification cursor-pointer" (click)="clear.emit()">
  <div class="d-flex align-items-end justify-content-end" (click)="closeNotification()">
    <span class="text-medium text-16  error-title"></span>
  </div>
  <div class="error-text-container">
        <span class="text-color-black-1 text-16">
            <ng-content></ng-content>
        </span>
  </div>
</div>
