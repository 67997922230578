import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})

export class SearchtApi {
 apiUrl = '/skrndapp'
 headers: HttpHeaders;
 token: string|null;
  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('view_auth_token');
    this.headers = new HttpHeaders().append('Authorization', `Basic ${this.token}`)
  }
  searchData(query) {
    return this.http.get(`${this.apiUrl}/search`,  {
      params: {
        query,
        page: 0,
        size: 30,
      },
      headers: this.headers
    })
   }
}
