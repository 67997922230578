import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {ModalBaseComponent} from "../../../../core/base/modal.base";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UserApi} from "../../../../shared/api/user.api";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormField, MatFormFieldModule, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput, MatInputModule} from "@angular/material/input";
import {CommonModule} from "@angular/common";
import {BarChartComponent} from "../../../../core/ui-components/charts/bar-chart/bar-chart.component";
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerModule,
  MatDatepickerToggle
} from "@angular/material/datepicker";
import {MatSelectModule} from "@angular/material/select";
import {MatNativeDateModule, provideNativeDateAdapter} from "@angular/material/core";
import {AddPhaseComponent} from "../../../main-settings/modal/add-phase/add-phase.component";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { ButtonComponent } from '../../../../core/ui-components/button/button.component';
import { TextareaComponent } from '../../../../core/ui-components/textarea/textarea.component';
import { DropDown, DropDownComponent } from '../../../../core/ui-components/dropdown/dropdown.component';
import { InputComponent } from '../../../../core/ui-components/input/input.component';
import { DomSanitizer } from '@angular/platform-browser';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-import-documents',
  standalone: true,
  imports: [
    FormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    CommonModule,
    BarChartComponent,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatSelectModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatDatepickerModule,
    MatDatepickerInput,
    MatNativeDateModule,
    MatIconModule,
    ButtonComponent,
    TextareaComponent,
    DropDownComponent,
    InputComponent
  ],
  providers: [
    MatDatepickerModule,
    provideNativeDateAdapter()
  ],
  templateUrl: './import-documents.component.html',
  styleUrl: './import-documents.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
    trigger('progressAnimation', [
      state('start', style({
        width: '100%',
        transform: 'translateX(100%)'
      })),
      state('end', style({
        width: '0%',
        transform: 'translateX(0)'
      })),
      transition('end => start', [
        animate('2s')
      ])
    ])
  ]
})
export class ImportDocumnetsComponent extends ModalBaseComponent implements OnInit {

    public isDropListAdd: DropDown[] | null = [];
    public selectedId: number | null = null;
    public isPlaceholder: string = 'Начните вводить название элементов или выберите из нижеперечисленных';
    public isCardId: any;

  constructor(
    override dialogRef: MatDialogRef<ImportDocumnetsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userApi: UserApi,
    private dialog:MatDialog,
    private changeDetectionRef: ChangeDetectorRef,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,

  ) {
    super(dialogRef);
  }
  protected subscriptions: Subscription[] = [];
  selectedFiles: File[] = [];
  isLoading: boolean[] =[];
  chartValue: any = []
  phaseArr: any = []
  colorList = ['#00D359','#FFB608','#FF4F12','#797E8B']
  infoForm = new FormGroup({
    name: new FormControl(''),
    description: new FormControl(''),
  })
  ngOnInit() {
    this.isDropListAdd = this.data?.dropList;
    this.selectedId = this.data.info?.id;
    this.isCardId = this.data?.cardId;

    this.matIconRegistry.addSvgIcon(
      'closeIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/close.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'chooseFileIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/choose-file.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'fileIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/file.svg'
      )
    );
    this.updatePlaceholder();
  }

  onSelectedIdChange(selectedId: number | null) {
    this.selectedId = selectedId;
    this.updatePlaceholder();
  }

  updatePlaceholder() {
    if (this.isDropListAdd && this.data.parentId) {
      const item = this.isDropListAdd.find(item => String(item.id) === String(this.data.parentId));
      if (item) {
        this.isPlaceholder = item.name;
      }
    }
  }

  onDragOver(event: any) {
    event.preventDefault();
  }

  onFilesDropped(event: any) {
    event.preventDefault();
    for (let i = 0; i < event.dataTransfer.files.length; i++) {
      this.selectedFiles.push(event.dataTransfer.files[i]);
      this.isLoading.push(true);
    }
    setTimeout(() => {
      this.isLoading.fill(false);
      this.changeDetectionRef.detectChanges();
    }, 2000);
  }

  onFilesSelected(event: any) {
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        this.selectedFiles.push(event.target.files[i]);
        this.isLoading.push(true);
      }
    }
    else {
      console.log('Ничего не выбрано.')
    }
    setTimeout(() => {
      this.isLoading.fill(false);
      this.changeDetectionRef.detectChanges();
    }, 2000);
  }

  onDeleteFile(index: number) {
    this.selectedFiles.splice(index, 1);
  }

  saveDocument() {
    const formData = new FormData();
    this.selectedFiles.forEach((file, index) => {
      console.log(file)
      formData.append(`content`, file, file.name);
      // formData.append(`content[${index}]`,JSON.stringify(this.selectedFiles))
    });
      let file = {
        content: formData,
        spaceId: this.selectedId || this.data.info.id,
        cardId: this.isCardId
      }
      console.log(file)
      setTimeout(() => {
        this.changeDetectionRef.detectChanges();
        this.dialogRef.close(file)
      }, 2000);
}
}
