import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class DocumentApi {
 apiUrl = '/skrndapp'
  headers: HttpHeaders;
  token: string|null;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('view_auth_token');
    this.headers = new HttpHeaders().append('Authorization', `Basic ${this.token}`)
  }

  getDocumentById(id: any) {
   return this.http.get(`${this.apiUrl}/document/${id}`, {
     headers: this.headers
   })
  }

  saveDocument(data:any) {
    return this.http.post(`${this.apiUrl}/document`, data, {
      headers: this.headers
    })
  }
  importFile(cardId, fileId,file) {
    return this.http.post(`${this.apiUrl}/project/${cardId}/document/${fileId}/import`, file, {
      headers: this.headers
    })
  }
  importFiles(spaceId, cardId, files:any) {
    return this.http.post(`${this.apiUrl}/space/${spaceId}/project/${cardId}/documents/import`,
      files, {
      headers: this.headers
    })
  }
  downloadOriginalDocument(id:any) {
    return this.http.get(`${this.apiUrl}/document/${id}/original`, {
      observe: 'response',
      responseType: 'blob',
      headers: this.headers
    })
  }
  changePhaseDocument(documentId, phaseId) {
    return this.http.post(`${this.apiUrl}/document/${documentId}/phase/${phaseId}`, {}, {
      headers: this.headers
    })
  }
  mergeDocument(data){
    return this.http.post(`${this.apiUrl}/combineFiles`, data, {
      headers: this.headers
    })
  }


  //------------------------------------------------------------------------------------------------------------------------------
  /** */
  getDocumentFile(id) {
    return this.http.get(`${this.apiUrl}/document/${id}/file`, {
      headers: this.headers,
      responseType: 'blob',
    })
  }
  //------------------------------------------------------------------------------------------------------------------------------
  /** фиксирует текущую версию документf.
   *  создает на сервере новую версию как копию текущей.
   *
   *  ! в настоящий момент не выкидыывает всех их редактирования текущей версии документа.
   *  т.е. текущая версия ещё может быть подправлена, если сейчас сушесьтвуют те,
   *  кто сейчас работает с файлом - скорее всего они будут продолжать успешно с ним работать.
   *  !ПРОВЕРИТЬ!
   */
  recordVersionHistory(documentId, comment) {
    return this.http.post(`${this.apiUrl}/commit/document/${documentId}`, comment,{
      headers: this.headers
    })
  }

  //------------------------------------------------------------------------------------------------------------------------------
  /** запрашивает с сервера перечень версий документа.
   * тут имеются в иду версии main-ветки - т.е. для каждой из них, возможно,
   * потребуется потом ещё запращивать бранчи (рабочие копии) созданные отдельными пользователями
   * IZ.2024.08.29
  */
  getDocumentVersionsById(id: any) {
    return this.http.get(`${this.apiUrl}/document/${id}/versions`,
                        { headers: this.headers
                        });
  }

  //------------------------------------------------------------------------------------------------------------------------------
  /** запрашивает у сервера создание сравнения двух версий.
   * отдает либреофис ID, по кторому можно получить временный файл с результатами сравнения -
   *  котоый описывает в режиме правки переход от docFrom_version к  docTo_version
   * IZ.2024.08.29
  */
  requestVersionsCompare(
                          docTo_id: any,
                          docTo_version: any,
                          docTo_branch: any,

                          docFrom_id: any,
                          docFrom_version: any,
                          docFrom_branch: any,
                        )
  {
    return this.http.post(//url:
                          `${this.apiUrl}/compareFiles`,
                          //body:
                          {
                                  "referenceDocumentId": docTo_id,
                                  "referenceFileVersion": docTo_version,
                                  "referenceBranchVersion": docTo_branch,
                                  "comparedDocumentId": docFrom_id,
                                  "comparedFileVersion": docFrom_version,
                                  "comparedBranchVersion": docFrom_branch
                          },
                          //options:
                          { headers: this.headers
                          }
                        );
  }

    //------------------------------------------------------------------------------------------------------------------------------
  /** запрашивает у сервера создание файла с объединением правок.
   *  отдает либреофис ID, по кторому можно получить временный файл с объединенными правками -
   * IZ.2024.09.17
  */
  requestBranchMergeToHead(
                          docId: any,
                          versionId: any,
                          branchId: any,
                        )
  {
    return this.http.post(//url:
                          `${this.apiUrl}/mergeFiles`,
                          //body:
                          {
                                  "documentId": docId,
                                  "rootVersion": versionId,
                                  "branchVersion": branchId,                                  
                          },
                          //options:
                          { headers: this.headers
                          }
                        );
  }

  //------------------------------------------------------------------------------------------------------------------------------

}
