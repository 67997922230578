import {NgModule} from "@angular/core";
import {MainSettingsComponent} from "./main-settings.component";
import {
  MainSettingsCharacteristicsComponent
} from "./components/main-settings-characteristics/main-settings-characteristics.component";
import {CommonModule} from "@angular/common";
import {MainSettingsRoutingModule} from "./main-settings-routing.module";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatFormField, MatFormFieldModule, MatLabel} from "@angular/material/form-field";
import {MatInput, MatInputModule} from "@angular/material/input";
import {MainSettingsEditComponent} from "./components/main-settings-edit/main-settings-edit.component";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BarChartComponent} from "../../core/ui-components/charts/bar-chart/bar-chart.component";
import {HttpClientModule} from "@angular/common/http";
import {BooleanModule} from "../../shared/pipes/boolean/boolean.module";
import {MatDatepickerInput, MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import { ButtonComponent } from "../../core/ui-components/button/button.component";
import { ProgressBarComponent } from "../../core/ui-components/progress-bar/progress-bar.component";
import { DatepickerComponent } from '../../core/ui-components/datepicker/datepicker.component';
import { DateMaskDirective } from '../../core/ui-components/datepicker/mask.directive';
import {InputComponent} from "../../core/ui-components/input/input.component";
import {DropDownComponent} from "../../core/ui-components/dropdown/dropdown.component";
import {TextareaComponent} from "../../core/ui-components/textarea/textarea.component";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatIcon} from "@angular/material/icon";

@NgModule({
  declarations: [
    MainSettingsComponent,
    MainSettingsCharacteristicsComponent,
    MainSettingsEditComponent,
    DateMaskDirective,
  ],
    imports: [
        CommonModule,
        MainSettingsRoutingModule,
        MatCheckbox,
        MatFormField,
        MatInput,
        MatLabel,
        MatSelectModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        BarChartComponent,
        HttpClientModule,
        BooleanModule,
        DatepickerComponent,
        ButtonComponent,
        MatDatepickerModule,
        MatDatepickerInput,
        MatNativeDateModule,
        ProgressBarComponent,
        InputComponent,
        DropDownComponent,
        TextareaComponent,
        MatExpansionModule,
        MatIcon
    ],
  exports: [
    DateMaskDirective,
    MainSettingsCharacteristicsComponent,
  ]
})
export class MainSettingsModule {}
