<div class="header-container d-flex justify-content-between align-items-center">
  <div class="d-flex align-items-center h-100">
    <span routerLink="/" class="text-16 cursor-pointer ">Система <br> Коллективной <br> Работы</span>
  </div>
  <div class="d-flex" *ngIf="isMenuItem">
    <div class="menu-item d-flex br-6" [ngClass]="activeComponent === 'main-settings' ? 'active': ''">
      <span class="text-16 text-700" (click)="routeTo('/main-settings')" > Общие настройки </span>
    </div>
    <div class="menu-item d-flex br-6" [ngClass]="activeComponent === 'work-material' ? 'active': ''">
      <span class="text-16 text-700" (click)="routeTo('/work-material')" > Рабочие материалы </span>
    </div>
    <div class="menu-item d-flex br-6" [ngClass]="activeComponent === 'task-tracker' ? 'active': ''">
      <span class="text-16 text-700" (click)="routeTo('/task-tracker')" > Таск трекер </span>
    </div>
  </div>
</div>
