import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ProjectApi} from "../../../../shared/api/project.api";
import {ActivatedRoute} from "@angular/router";
import {MainProjectLayoutService} from "../../../main-project-layout/main-project-layout.service";

@Component({
  selector: 'app-main-settings-edit',
  templateUrl: './main-settings-edit.component.html',
  styleUrl: './main-settings-edit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainSettingsEditComponent implements OnInit{
  id:any;
  projectData: any;
  constructor(
    private projectApi: ProjectApi,
    private activatedRoute: ActivatedRoute,
    private changeDetection: ChangeDetectorRef,
    private mainService: MainProjectLayoutService
  ) {
    mainService.id.subscribe((res) => {
      this.id = res;
    })
  }
  ngOnInit() {
    this.getProjectById();
  }
  getProjectById() {
   this.projectApi.getProjectById(this.id)
     .subscribe((res: any) => {
       this.projectData = res;
       this.changeDetection.markForCheck();
     })
  }

}
