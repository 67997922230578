import {Injectable} from "@angular/core";
import {debounceTime, Subject, tap} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  notifications: any = [];
  message$: Subject<string> = new Subject();
  longTimeMessage$: Subject<string> = new Subject();
  bigMessage$: Subject<string> = new Subject();
  clear$: Subject<string> = new Subject();

  constructor() {
    this.message$
      .pipe(
        debounceTime(100),
        tap((message) => {
          console.log(message)
          this.notifications.push(message)
        }),
      )
      .subscribe((_) => {
        setTimeout(() => this.notifications.shift(), 3000);
      });
    this.longTimeMessage$
      .pipe(
        debounceTime(100),
        tap((message) => this.notifications.push(message)),
      )
      .subscribe((_) => {
        setTimeout(() => this.notifications.shift(), 6000);
      });
    this.bigMessage$
      .pipe(
        debounceTime(100),
        tap((message: any) => this.notifications.push(message)),
      )
      .subscribe((_) => {
        // setTimeout(() => this.notifications.shift(), 10000);
      });
    this.clear$
      .subscribe(() => {
        this.notifications.shift();
      })
  }

}
