<div class="sidebar-container" [ngClass]="{'sidebar-container-large' : isVersionHistoryOpen}">
  <div class="sidebar-header">
    <img src="../../../assets/icons/skrnd.svg" />
    <img src="../../../assets/icons/close-sidebar.svg" />
  </div>
  <ng-container *ngIf="!isVersionHistoryOpen">
    <div class="sidebar-title">
      <span class="sidebar-title-text">Проекты</span>
    </div>
    <div class="sidebar-heigth">
      <div class="sidebar-heigth-scroll">
        <div
          class="sidebar-heigth-scroll-item text-14 text-600"
          [routerLink]="'project/' + project.id"
          routerLinkActive="sidebar-heigth-scroll-item-active"
          (click)="updateId(project.id)"
          *ngFor="let project of projectList"
        >
          {{ project.name }}
        </div>
      </div>
      <div class="sidebar-title">
        <span class="sidebar-title-text">Действия</span>
      </div>
      <div class="sidebar-heigth-middle-block">
        <div class="sidebar-heigth-middle-block__button" routerLink="/add_project" routerLinkActive="active">
          <mat-icon *ngIf="true" class="plus action-icon" aria-hidden="false" aria-label="Example home icon" svgIcon="plus"></mat-icon>
          <div class="sidebar-heigth-middle-block__button-title">Создать новый проект</div>
        </div>
        <div class="sidebar-heigth-middle-block__button" routerLink="/tasks" routerLinkActive="active">
          <mat-icon *ngIf="true" class="plus action-icon" aria-hidden="false" aria-label="Example home icon" svgIcon="folder"></mat-icon>
          <div class="sidebar-heigth-middle-block__button-title">Мои задачи</div>
        </div>
      </div>
    </div>
    <div class="sidebar-footer">
      <div class="sidebar-footer-top" style = "margin-bottom: 0px;">
        <!-- 2024.07.29 временно комментируем , пока кнопка не обрела смысл и собственную менюшку. style = "margin-bottom: 27px;" туда же-->

        <!-- <div class="sidebar-footer-top__button" routerLink="/add_project" routerLinkActive="active"> -->
        <div class="sidebar-footer-top__button" style="cursor: not-allowed; opacity: 50%;" >
          <mat-icon *ngIf="true" class="setting action-icon" aria-hidden="false" aria-label="Example home icon" svgIcon="setting"></mat-icon>
          <!-- <div class="sidebar-footer-top__button-title">Настройки</div>-->
          <div class="sidebar-footer-top__button-title" style="cursor: not-allowed;" >Настройки</div>
        </div>
        <!-- -->
      </div>
      <div class="sidebar-footer-user">
        <img src="../../../assets/icons/avatar.svg" alt="image" />
        <div class="sidebar-footer-user__info">
          <div class="sidebar-footer-user__info-title">{{currentUser.firstName}} {{currentUser.lastName}}</div>
          <div class="sidebar-footer-user__info-button" (click)="logout()">
            Выйти
            <mat-icon *ngIf="true" class="exit action-icon" aria-hidden="false" aria-label="Example home icon" svgIcon="exit"></mat-icon>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<ng-content></ng-content>
