import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NavigationMenuSidebarComponent} from "./navigation-menu-sidebar.component";
import {RouterLink, RouterLinkActive} from "@angular/router";
import { MatIconModule} from "@angular/material/icon";

@NgModule({
  declarations: [
    NavigationMenuSidebarComponent
  ],
  exports: [
    NavigationMenuSidebarComponent
  ],
  imports: [
    CommonModule,
    RouterLinkActive,
    RouterLink,
    MatIconModule,
  ]
})
export class NavigationMenuSidebarModule {}
