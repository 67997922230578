<div class="main-container">
  <div class="d-flex justify-content-between">
    <div class="title text-22" >
      <span class="text-700">{{isEditing ? 'Редактирование' : ''}}  {{projectData?.name}}</span>
    </div>
    <div class="d-flex" *ngIf="!isEditing">
      <app-button class="" [titleButton]="'Редактировать'" [isRegular]="true" (click)="openSettingsEdit()">
       Редактировать
      </app-button>
    </div>
  </div>
  <app-main-settings-characteristics [data]="projectData" [isEdit]="isEditing" (valueChange)="openSettingsEdit()"></app-main-settings-characteristics>
</div>
