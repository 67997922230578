import {Component, EventEmitter, OnDestroy, OnInit, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef, Pipe, PipeTransform} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterLink, RouterLinkActive} from "@angular/router";
import {filter, Subscription} from "rxjs";
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropDown, DropDownComponent } from '../../core/ui-components/dropdown/dropdown.component';
import { TextareaComponent } from '../../core/ui-components/textarea/textarea.component';
import { MatDatepicker, MatDatepickerInput, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { ButtonComponent } from '../../core/ui-components/button/button.component';
import { MatInput } from '@angular/material/input';
import { SearchtApi } from '../../shared/api/search.api';
import { DatepickerComponent } from '../../core/ui-components/datepicker/datepicker.component';
import { ProgressBarComponent } from '../../core/ui-components/progress-bar/progress-bar.component';
import { ProjectApi } from '../../shared/api/project.api';
import { BarChartComponent } from '../../core/ui-components/charts/bar-chart/bar-chart.component';
import { NotificationsService } from '../../core/ui-components/notification/services/notification.service';

@Component({
  imports: [
    MatIconModule,
    CommonModule,
    FormsModule,
    DropDownComponent,
    TextareaComponent,
    MatDatepicker,
    MatDatepickerModule,
    MatLabel,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatDatepickerInput,
    MatNativeDateModule,
    MatInput,
    ButtonComponent,
    DatepickerComponent,
    ProgressBarComponent,
    BarChartComponent,
    RouterLink,
    RouterLinkActive

  ],
    standalone: true,
    selector: 'app-page-search',
    templateUrl: './search-page.component.html',
    styleUrl: './search-page.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SearchPageComponent implements OnInit {

  protected subscriptions: Subscription[] = [];

  dateSearch = new FormGroup({
    from: new FormControl(''),
    to: new FormControl(''),
  })

  public isPlaceholderType: string = 'Всем';
  public isPlaceholderAttribute: string = 'Всем';
  public selectedId: number | null = null;

  public typeDropDown: DropDown[] = [
    {id: 1, name: 'Все', router: '',},
    {id: 2, name: 'Проекты', router: '',},
    {id: 3, name: 'Документы', router: '',},
    {id: 4, name: 'To-do листы', router: '',},
  ]
  public atributeDropDown: DropDown[] = [
    {id: 1, name: 'Все', router: '',},
    {id: 2, name: 'Наименование', router: '',},
    {id: 3, name: 'Описание', router: '',},
    {id: 4, name: 'Содержимое', router: '',},
    {id: 5, name: 'Автор', router: '',},
  ]

  public isSearchText: string;

  @Input() isDisabled: boolean = false;

  public searchValue: string = '';
  public resultSearch: any[]=[];
  @Input() searchData: { searchValue: string; resultSearch: any[] } | null = null;
  public isResult: boolean = false;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private searchApi: SearchtApi,
    private changeDetectionRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private notification: NotificationsService,
    private router: Router
  ) {

  }
  dataSearch() {
    if (this.searchValue.trim().length >= 3) {
      this.searchApi.searchData(this.searchValue).subscribe(response => {
        this.resultSearch = response as any[];
        console.log(this.resultSearch)
        this.changeDetectionRef.markForCheck();
      });
    } else {
      this.notification.message$.next('Пождалуйста введи не менее 3-х символов')
    }
  }

  ngOnInit() {
      this.route.queryParams.subscribe(params => {
        console.log(params);
      this.searchValue = params['searchValue'] || '';
      this.dataSearch();
    })
    console.log(this.searchValue, this.resultSearch)
    this.isSearchText = '';
    this.selectedId = 1;
    this.updatePlaceholder();
    this.matIconRegistry.addSvgIcon(
      'linkIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/search-link.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'projectIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/search-project.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'documentIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/search-document.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'commentIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/search-comment.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'creatorIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/creator.svg'
      )
    );
  }

  updatePlaceholder() {
    if (this.typeDropDown) {
      const item = this.typeDropDown.find(item => String(item.id));
      if (item) {
        this.isPlaceholderType = item.name;
      }
    }
    if (this.atributeDropDown) {
      const item = this.atributeDropDown.find(item => String(item.id));
      if (item) {
        this.isPlaceholderAttribute = item.name;
      }
    }
  }

  transform(value: string): string {
    if (!value) {
      return '';
    }
    return value.replace(/-/g, ' / ');
  }

  onSelectedIdChange(selectedId: number | null) {
    this.selectedId = selectedId;
    this.updatePlaceholder();
  }

  getCombinedContent(highlighting: any): string {
  if (highlighting && Array.isArray(highlighting.content)) {
    return highlighting.content.join(', ');
  }
  return '';
  }

  getIconName(type: string) {
    switch (type) {
      case '[PROJECT]':
        return 'projectIcon';
      case '[DOCUMENT]':
        return 'documentIcon';
      case '[COMMENT]':
        return 'commentIcon';
      default:
        return '';
    }
  }

  getHighlightedContent(content: string[][]): string {
    if (content && content.length > 0) {
      const flatContent = content.flat();
      return flatContent
        .join(', ')
        .replace(/<mark>(.*?)<\/mark>/g, '<span class="highlight">$1</span>');
    }
    return 'Нет данных';
  }
  routeToEntity(entity) {
    if(entity.type == '[PROJECT]') {
      this.router.navigate(['/project/'+ entity.entityId.replace("[", '').replace("]",'')])
    }
    if(entity.type == '[DOCUMENT]') {
      const idMass = entity.id.split('-')
      this.router.navigate(['/project/'+ idMass[2]+ '/work-material/document/'+idMass[0] ])
    }
  }

}
