<div class="datepicker w-100">
  <div class="datepicker__label" [ngClass]="{'disabled': isDisabled}">
    {{ label }}
  </div>
  <div class="datepicker__input" (click)="isDisabled ? false : toggleCalendar()">
    <input type="text" placeholder="ДД.ММ.ГГГГ" [(ngModel)]="value" (input)="onDateInputChange($event)" [readonly]="isDisabled">
    <mat-icon *ngIf="true" class="datepicker-icon" svgIcon="calendar"></mat-icon>
  </div>
  <div class="datepicker__calendar" *ngIf="isCalendarVisible">
    <div class="datepicker__header">
      <select class="datepicker__month-select" [(ngModel)]="currentMonth" (change)="generateCalendar(currentYear, currentMonth)">
        <option *ngFor="let month of months; let i = index" [value]="i">{{ month }}</option>
      </select>
      <select class="datepicker__year-select" [(ngModel)]="currentYear" (change)="generateCalendar(currentYear, currentMonth)">
        <option *ngFor="let year of years" [value]="year">{{ year }}</option>
      </select>

      <mat-icon *ngIf="true" class="datepicker__arrow" (click)="previousMonth()" svgIcon="arrow-left"></mat-icon>
      <mat-icon *ngIf="true" class="datepicker__arrow" (click)="nextMonth()" svgIcon="arrow-right"></mat-icon>
    </div>
    <div class="datepicker__weekdays">
      <span class="datepicker__weekday" *ngFor="let day of weekdays">{{ day }}</span>
    </div>
    <div class="datepicker__days">
      <span *ngFor="let empty of emptyDays" class="datepicker__empty-day"></span>
      <span class="datepicker__day" *ngFor="let day of daysInMonth" (click)="selectDate(day)" [class.datepicker__day--selected]="isSelected(day)">
        {{ day }}
      </span>
    </div>
    <div class="datepicker__actions">
      <button class="datepicker__action-button" (click)="cancel()">Отменить</button>
      <button class="datepicker__action-button confirm" (click)="confirm()">Выбрать</button>
    </div>
  </div>
</div>
