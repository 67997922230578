import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class UserApi {
  apiUrl = '/skrndapp'
  headers: HttpHeaders;
  token: string|null;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('view_auth_token');
    this.headers = new HttpHeaders().append('Authorization', `Basic ${this.token}`)
  }

  getUserList() {
    return this.http.get(`${this.apiUrl}/users`, {
      params: {
        page: 0,
        size: 40,
      },
      headers: this.headers
    })
  }
  getRoleList() {
    return this.http.get(`${this.apiUrl}/dictionary/roles`, {
      headers: this.headers
    })
  }
  getUserInfo() {
    return this.http.get(`${this.apiUrl}/user/userinfo`, {
      headers: this.headers
    })
  }
}
