<div class="progress-bar" [ngClass]="{'large': isSize}" *ngIf="chartData.length > 0">
    <div
        class="progress-bar__item"
        *ngFor="let item of chartData"
        [ngStyle]="{'flex-grow': item.daysDifference}">
        <div class="progress-bar__item-name w-full">{{ item.name }}</div>
        <div class="progress-bar__item-dates w-full" *ngIf="isSize">
            <!-- Date from -->
            <div class="date">
                {{ item.startPlan | date:'dd.MM.yyyy' }}
            </div>
            <!-- Date to -->
            <div class="date">
                {{ item.endPlan | date:'dd.MM.yyyy' }}
            </div>
        </div>
        <!-- Progress bar -->
        <div
            class="progress-bar__item-block w-full"
            [ngClass]="{
                'in-progress': item.status == 'IN_PROGRESS',
                'plan': item.status == 'PLAN',
                'completed': item.status == 'COMPLETED',
            }"
        ></div>
    </div>
</div>
<div *ngIf="chartData.length == 0" class="progress-bar justify-content-center" [ngClass]="{'large': isSize}"  >
  <div class="progress-bar__item">
    <div class="progress-bar__item-empty-data w-full">Фаз нет, добавьте фазу</div>
  </div>
</div>
