import {Component, EventEmitter, OnDestroy, OnInit, Input, Output} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter, Subscription} from "rxjs";
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

@Component({
    imports: [
        MatIconModule,
        CommonModule,
    ],
    standalone: true,
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrl: './button.component.scss',
})

export class ButtonComponent implements OnInit {
    @Input() titleButton: string = '';
    @Input() isSolid: boolean = true;
    @Input() isRegular: boolean = false;
    @Input() isText: boolean = false;
    @Input() isIcon: string = '';
    @Input() isDoubleIcon: boolean = false;
    @Input() isDisabled: boolean = false;
    @Input() isTextUnderline: boolean = false;
    @Input() isRegularForMonth: boolean = false;
    @Output() monthChange = new EventEmitter<number>();

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    // console.log(this.isIcon, 'this.isIcon')
  }

  ngOnInit() {
    this.matIconRegistry.addSvgIcon(
        'arrow',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-right.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'arrow-right',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-right.svg')
  );
    this.matIconRegistry.addSvgIcon(
      'arrow-left',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-left.svg')
    );
    this.matIconRegistry.addSvgIcon(
        'trash',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/trash.svg')
    );
  }

  onPreviousMonth(event: MouseEvent) {
    event.stopPropagation();
    this.monthChange.emit(-1);

}
  onNextMonth(event: MouseEvent) {
    event.stopPropagation();
    this.monthChange.emit(1);
  }
}
