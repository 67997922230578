import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {HeaderModule} from "../header/header.module";
import {NavigationMenuSidebarModule} from "../navigation-menu-sidebar/navigation-menu-sidebar.module";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [CommonModule, HeaderModule, NavigationMenuSidebarModule],
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainLayoutComponent {

}
