import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {ModalBaseComponent} from "../../../../core/base/modal.base";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ButtonComponent} from "../../../../core/ui-components/button/button.component";
import {DropDownComponent} from "../../../../core/ui-components/dropdown/dropdown.component";
import {MatIcon, MatIconRegistry} from "@angular/material/icon";
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {DocumentApi} from "../../../../shared/api/document.api";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {AttachmentApi} from "../../../../shared/api/attachment.api";

@Component({
  selector: 'app-view-file',
  standalone: true,
  imports: [
    ButtonComponent,
    DropDownComponent,
    MatIcon,
    NgForOf,
    NgIf,
    NgOptimizedImage
  ],
  templateUrl: './view-file.component.html',
  styleUrl: './view-file.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewFileComponent extends ModalBaseComponent implements OnInit {
  image: SafeUrl;
  test: '';
  openImage = false;
  openDocument = false;
  constructor(
    override dialogRef: MatDialogRef<ViewFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private documentApi: DocumentApi,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private attachmentApi: AttachmentApi,
    private changeDetection: ChangeDetectorRef
  ) {
    super(dialogRef);
  }
  ngOnInit() {
    if(this.data.type == 'document') {
      this.getImage();
    }
    if( this.data.type == 'attachment') {
      this.getAttachment();
    }
    this.matIconRegistry.addSvgIcon(
      'closeIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/close.svg'
      )
    );
  }
  getImage() {
   this.documentApi.getDocumentFile(this.data.info.id).subscribe((result) => {
     let objectUrl = URL.createObjectURL(result)
     this.image = this.domSanitizer.bypassSecurityTrustUrl(objectUrl)
     this.openImage = true;
     this.changeDetection.markForCheck();
   })
  }
  getAttachment() {
    if(this.data.file.mimeType =='JPG' || this.data.file.mimeType =='PNG') {
      this.attachmentApi.downloadAttachmentById(this.data.file.id).subscribe((result) => {
        let objectUrl = URL.createObjectURL(result)
        this.image = this.domSanitizer.bypassSecurityTrustUrl(objectUrl)
        this.openImage = true;
        this.changeDetection.markForCheck();
      })
    } else {
      this.image = this.domSanitizer.bypassSecurityTrustResourceUrl(`../../../../../assets/viever/#/skrndapp/attachment/${this.data.file.id}/file?access_token=${localStorage.getItem('view_auth_token')}`)
      this.openDocument = true;
    }
  }
}
