import {Injectable} from "@angular/core";
import {KeycloakAuthGuard, KeycloakService} from "keycloak-angular";
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
import { CurrentUserService } from "../shared/services/current-user.service";

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    override readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private currentUserService: CurrentUserService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      localStorage.setItem('view_auth_token', '');
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }
    this.keycloak.getToken().then((val) => {
      localStorage.setItem('view_auth_token', val);
    });
    this.keycloak.loadUserProfile().then((res) => {
      this.currentUserService.currentUser.next(res)
    })
    return true;
  }

}
