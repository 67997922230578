import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NotificationsService} from "./services/notification.service";

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss',
})
export class NotificationComponent implements OnInit {
  @Output()
  public clear: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private notification: NotificationsService
  ) {
  }
  ngOnInit() {
  }

  closeNotification() {
    this.notification.clear$.next('y')
  }

}
