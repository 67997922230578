<div class="navigation-container">

</div>
<div class="page-search-container">
  <div class="page-search-container-search">
    <app-textarea
    [isSearch]="true"
    [label]="'Поиск'"
    [isPlaceholder]="'Начните вводить'"
    [(value)]="searchValue"
  ></app-textarea>
  </div>
  <label class="header text-18 text-700">Параметры поиска</label>
  <div class="page-search-container-params">
    <app-dropdown
    [type]="'text'"
    [label]="'Выбрать тип'"
    [placeholder]="isPlaceholderType"
    [listDropdown]="typeDropDown"
    (valueChange)="onSelectedIdChange($event)"
  ></app-dropdown>
  <app-dropdown
    [type]="'text'"
    [label]="'Атрибут'"
    [placeholder]="isPlaceholderAttribute"
    [listDropdown]="atributeDropDown"
    (valueChange)="onSelectedIdChange($event)"
  ></app-dropdown>
  <app-datepicker [isDisabled]="false" [label]="'Выбрать период (-с)'"></app-datepicker>
  <app-datepicker [isDisabled]="false" [label]="'Выбрать период (-по)'"></app-datepicker>
  <app-button
  style="margin-top: 24px;"
  (click)="dataSearch()"
  [titleButton]="'Поиск'"
  [isSolid]="true"
  [isRegular]="false"
></app-button>
  </div>
  <label class="header text-18 text-700">Результаты поиска: <span class="search-number">{{resultSearch.length}}</span> совпадений</label>
  <div class="scroll">
  <div class="page-search-container-results" *ngFor="let result of resultSearch; let i = index">
    <div class="page-search-container-results-item">
      <div class="page-search-container-results-item-top_row">
        <div class="page-search-container-results-item-row">
        <mat-icon
        class="search-link"
        *ngIf="true"
        [svgIcon]="getIconName(result.type)"
      ></mat-icon>
      <span class="result-name text-14 text-400">Название: {{result.name | slice: 1: -1}}</span>
    </div>
    <div class="page-search-container-results-item-row">
      <mat-icon
      class="search-link"
      *ngIf="true"
      svgIcon="creatorIcon"
    ></mat-icon>
    <span class="result-name text-14 text-400">{{result.creator | slice: 1: -1}}</span>
  </div>
  <div class="page-search-container-results-item-row">
  <!-- <app-progress-bar [isSize]="false" [chartData]="phaseArr"></app-progress-bar> -->
</div>
      <mat-icon
      class="search-link cursor-pointer"
      *ngIf="true"
      (click)="routeToEntity(result)"
      svgIcon="linkIcon"
    ></mat-icon>
  </div>
      <div class="page-search-container-results-item-row">
      <span class="level text-10 text-400">{{transform(result.id)}}</span>
      <span class="level text-10 text-400"></span>
    </div>
    <div class="page-search-container-results-item-row" *ngIf="result.highlightings.content && result.highlightings.content.length > 0">
      <span class="content_name text-14 text-700">Содержимое:</span>
        <span class="content text-14 text-400" [innerHTML]="getHighlightedContent(result.highlightings.content)"></span>
    </div>
    <div class="page-search-container-results-item-row" *ngIf="result.highlightings.name && result.highlightings.name.length > 0">
      <span class="content_name text-14 text-700">Наименование:</span>
      <span class="content text-14 text-400" [innerHTML]="getHighlightedContent(result.highlightings.name)"></span>
    </div>
    <div class="page-search-container-results-item-row" *ngIf="result.highlightings.description && result.highlightings.description.length > 0">
      <span class="content_name text-14 text-700">Описание:</span>
      <span class="content text-14 text-400" [innerHTML]="getHighlightedContent(result.highlightings.description)"></span>
    </div>
    </div>
  </div>
</div>
</div>
