import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'boolean',
})
export class BooleanPipe implements PipeTransform {
  transform(value: boolean | 'true' | 'false', ...args): string {
    if (typeof value === 'string') {
      return value === 'true' ? 'Да' : 'Нет';
    }
    if (!!args.length) {
      return value ? args[0] : args[1];
    }
    if (value === null) {
      return ' - ';
    }
    if (typeof value !== 'boolean') {
      return ' - ';
    }
    return value ? 'Да' : 'Нет';
  }
}
