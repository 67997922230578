<div class="import-documents-container">
  <div class="header">
    <div class="title">
      <span class="text-18 text-600">Импортировать файлы</span>
    </div>
    <mat-icon
      class="item-close"
      *ngIf="true"
      svgIcon="closeIcon"
      (click)="closeDialog()"
    ></mat-icon>
  </div>
  <div class="import-documents-container-inside-block">
    <app-dropdown
      [type]="'text'"
      [label]="'Выбрать родительский элемент'"
      [placeholder]="isPlaceholder"
      [listDropdown]="isDropListAdd"
      (valueChange)="onSelectedIdChange($event)"
      >
      </app-dropdown>
  </div>
  <label class="text-14 text-600 label">Загрузить файл(-ы)</label>
  <div class="import-documents-container-inside-block">
    <div class="import-documents-container-inside-block-import-window" (drop)="onFilesDropped($event)" (dragover)="false">
      <mat-icon
        class="item-folder"
        *ngIf="true"
        svgIcon="chooseFileIcon"
      ></mat-icon>
      <label class="import-label text-16 text-400"
        >Перенесите файл в эту область
        <span>или 
          <a style="cursor: pointer;" class="import-label-link"><b>Выбрать файл</b></a>
          <input #fileInput type="file" style="display: none;"  multiple (change)="onFilesSelected($event)">
        </span>
      </label>
      <span class="formats text-14 text-400">Допустимые форматы: pdf, doc</span>
    </div>
    <div class="import-documents-container-inside-block-scroll">
      <div class="import-documents-container-inside-block-scroll-item" *ngFor="let file of selectedFiles; let i = index">
        <div class="import-documents-container-inside-block-scroll-item-name">
          <mat-icon *ngIf="true" svgIcon="fileIcon"></mat-icon>
          <span class="fileName text-14 text-400">{{file.name}}</span>
        </div>
        <div class="loading-indicator" *ngIf="isLoading[i]">
          <div>
            <div class="fill" [@progressAnimation]></div>
          </div>
        </div>
        <app-button *ngIf="!isLoading[i]"  (click)="onDeleteFile(i)" [isRegular]="true" [isIcon]="'trash'"></app-button>
      </div>
    </div>
  </div>
  <div class="import-documents-container-footer">
    <app-button
      [titleButton]="'Отменить'"
      [isSolid]="false"
      [isRegular]="true"
      (click)="closeDialog()"
      >Отмена</app-button
    >
    <app-button
      (click)="saveDocument()"
      [titleButton]="'Сохранить'"
      [isSolid]="true"
      [isRegular]="false"
    ></app-button>
  </div>
</div>
