import { Component, OnInit} from '@angular/core';
import {NotificationsService} from "../services/notification.service";
import {listAnimation} from "../../../animation/opacity.animation";

@Component({
  selector: 'app-notification-container',
  templateUrl: './notification-container.component.html',
  styleUrl: './notification-container.component.scss',
  animations: [listAnimation],
})
export class NotificationContainerComponent  implements OnInit  {
  constructor(public notifications: NotificationsService) {
  }


  ngOnInit() {
  }
}
