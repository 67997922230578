<div class="main-page-container">
  <div class="text-22 main-info-container app-mt-10px main-page-welcome-block"
    style=" border-style: solid;
            border-radius: 10px;
            align-self: center;
            min-width: 600px;
            max-width: 800px;

            min-height: 400px;
            max-height: 800px;

            border-width: 1px;
            border-color: lightgray;
            text-align: center;
            align-content: center;
            justify-self: center;
          ">
   Добро пожаловать в систему коллективной работы ИСКРА.<br><br>
  Это лучший инструмент для организации рабочего пространства создания документов. <br>
  <!-- Система организована как иерархия страниц. Вы можете создать несколько проектов, каждыйиз которых будет иметь собственный набор страниц. -->
  </div>

</div>
