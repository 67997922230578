import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef, EventEmitter,
  Input,
  OnInit, Output, ViewChild,
} from '@angular/core';
import {ProjectApi} from "../../../../shared/api/project.api";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {UserApi} from "../../../../shared/api/user.api";
import {NotificationsService} from "../../../../core/ui-components/notification/services/notification.service";
import moment from "moment/moment";
import {UpdateProjectListService} from "../../../../shared/services/update-project-list.service";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {AttachmentApi} from "../../../../shared/api/attachment.api";
import {ViewFileComponent} from "../../../work-material/modal/view-file/view-file.component";

interface LoadingStateMainSettings {
  fileName: string;
  isLoading: boolean;
}

interface AttachmentMainSettings {
  id: number;
  fileUuid: string;
  name: string;
  createdDate: string;
  updateDate: string | null;
  creator: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  };
  updateUser: string | null;
  parentType: string;
  parentId: number;
}

@Component({
  selector: 'app-main-settings-characteristics',
  templateUrl: './main-settings-characteristics.component.html',
  styleUrl: './main-settings-characteristics.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainSettingsCharacteristicsComponent implements OnInit {
  @Output() valueChange = new EventEmitter<any>();
  @Input() isEdit: boolean;
  @Input() isAdd: boolean = false;
  @Input() set data(value:any) {
     this.projectData = value;
     this.init()
  }
  get data() {
    return this.projectData;
  }
  projectData: any;
  chartData: any = [];
  documentHandlingModeArray: string[] = []
  documentHandlingModeList: any[] = [
    {name: 'Работа с ветками', id: 1},
    {name: 'Онлайн правки', id: 2}
  ]
  phaseStatusList: any[] = [
    {
      name:'PLAN',
      id: 'PLAN'
    },
    {
      name:'IN_PROGRESS',
      id: 'IN_PROGRESS'
    },
    {
      name:'COMPLETED',
      id: 'COMPLETED'
    },
  ]
  roleList: any[] = [];
  membersObject = {
    curator: [''],
    responsible: [''],
    approver: [''],
    member_work_group: ['']
  }
  userList: any = []

  @ViewChild('fileInput') fileInput!: ElementRef;
  isLoading: LoadingStateMainSettings[] = [];
  attachmentsData: AttachmentMainSettings[] = [];
  public projectType: string = 'PROJECT';

  // public getDatepiker: string = '14.07.2025';

  constructor(
    private projectApi: ProjectApi,
    private router: Router,
    private dialog:MatDialog,
    private changeDetectionRef: ChangeDetectorRef,
    private userApi: UserApi,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private notificationService: NotificationsService,
    private projectListUpdateService: UpdateProjectListService,
    private attachmentApi: AttachmentApi,
  ) {
  }

  ngOnInit() {
    this.getUserList();
    this.getRoleList();
    if(this.isAdd) {
      this.init()
    }
    this.matIconRegistry.addSvgIcon(
      'closeIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/close.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'openFileIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/openFile.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'downloadFileIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/downloadFile.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'fileIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/file.svg'
      )
    );



  }
  init() {
    if( this.projectData) {
      this.documentHandlingModeArray.push(this.projectData.onlineMode ? 'Онлайн правки' : '')
      this.documentHandlingModeArray.push(this.projectData.branchMode ? 'Работа с ветками' : '')
      this.membersObject = {
        curator: this.projectData.members['CURATOR']?.map(res => res.firstName + ' ' + res.lastName),
        responsible: this.projectData.members['RESPONSIBLE']?.map(res => res.firstName + ' ' + res.lastName),
        approver: this.projectData.members['APPROVER']?.map(res => res.firstName + ' ' + res.lastName),
        member_work_group: this.projectData.members['MEMBER_WORK_GROUP']?.map(res => res.firstName + ' ' + res.lastName),
      }
     this.projectData?.lifeCycle.phases.map(res => {
        res.isEdit = false;
        return res;
      })
      this.chartData = this.projectData?.lifeCycle.phases
      this.getAttachmentsMainSettings(this.projectType, this.projectData.id);
    }
  }

  saveProject() {
    if(this.projectData.lifeCycle.phases.length == 0) {
      this.notificationService.message$.next('Необходимо добавить фазу')
      return;
    }
    if(this.projectData.lifeCycle.phases.length > 0) {
      let isError = false;
      this.projectData.lifeCycle.phases.map(res => {
        console.log(res)
        if(res.isEdit) {
          this.notificationService.message$.next('Имеются не сохраненные фазы, пожалуйста сохраните их')
          isError = true;
        }
        if (moment(res.startPlan,'DD.MM.YYYY').format() !== 'Invalid date') {
          res.startPlan = moment(res.startPlan,'DD.MM.YYYY').format()
        }
        if (moment(res.endPlan,'DD.MM.YYYY').format() !== 'Invalid date') {
          res.endPlan = moment(res.endPlan,'DD.MM.YYYY').format()
        }
      })
      if (isError) {
        return;
      }
    }
    if (this.projectData.startPlan == undefined) {
      this.notificationService.message$.next('Заполните дату начала проекта')
      return;
    }
    if (this.projectData.endPlan == undefined) {
      this.notificationService.message$.next('Заполните дату окончания проекта')
      return;
    }
    if ( moment(this.projectData.startPlan,'DD.MM.YYYY').format() !== 'Invalid date') {
      this.projectData.startPlan = moment(this.projectData.startPlan,'DD.MM.YYYY').format();
      this.projectData.lifeCycle.startPlan = this.projectData.startPlan;
    }
    if ( moment(this.projectData.endPlan,'DD.MM.YYYY').format() !== 'Invalid date') {
      this.projectData.endPlan = moment(this.projectData.endPlan,'DD.MM.YYYY').format();
      this.projectData.lifeCycle.endPlan = this.projectData.endPlan
    }
    this.projectApi.saveProject(this.projectData)
      .subscribe((res: any) => {
        if(this.isAdd) {
          this.projectListUpdateService.projectListUpdate.next(res.id)
          this.router.navigate([`/project/${res.id}/main-settings`]).then()
        }
        this.projectData = res;
        this.init();
        this.valueChange.emit()
      }, () =>  {
        this.notificationService.message$.next('Заполните все поля')
      })
  }

  addPhase() {
    this.projectData.lifeCycle.phases.push(
      {
        id: null,
        name: '',
        status: '',
        description: '',
        startPlan: '',
        endPlan: '',
        rights: [
          {
            accessLevel: "ADMIN",
            roles: []
          },
          {
            accessLevel: "EDIT",
            roles: []
          },
          {
            accessLevel: "READ",
            roles: []
          },
          {
            accessLevel: "APPROVE",
            roles: []
          },
          {
            accessLevel: "CHANGE_STATUS",
            roles: []
          },
        ],
        startFact: '',
        endFact: '',
        isEdit: true
      }
    )
    this.changeDetectionRef.markForCheck();
  }
  editPhase(phase) {
    phase.isEdit = true;
  }
  savePhase(phase, index) {
    if(phase.name.length == 0) {
      this.notificationService.message$.next('Заполните наименование фазы')
      return
    }
    if(phase.startPlan.length == 0) {
      this.notificationService.message$.next('Заполните дату начала фазы')
      return
    }
    phase.isEdit = false;
    this.chartData = this.projectData.lifeCycle.phases;
    this.changeDetectionRef.markForCheck();
  }
  deletePhase(phase) {
    this.projectData.lifeCycle.phases.splice(this.projectData.lifeCycle.phases.indexOf(phase), 1)
    this.chartData = this.projectData.lifeCycle.phases;
    this.changeDetectionRef.markForCheck();
  }
  getUserList() {
    this.userApi.getUserList()
      .subscribe((res: any) => {
       this.userList = res.content.map((item) => {
         item.name = item.firstName + ' ' + item.lastName
         return item;
       })
        this.changeDetectionRef.markForCheck();
      })
  }
  getRights(rightsArr, searchRight ) {
    return rightsArr.find(res => res.accessLevel == searchRight)?.roles
  }
  getRoleList() {
    this.userApi.getRoleList()
      .subscribe((res:any) => {
        this.roleList = res;
      })
  }
  selectMember(id,member) {
    this.projectData.members[member] = id.map(res => { return {id: res}});
    this.changeDetectionRef.markForCheck();
  }
  selectPhaseStatus(id, phaseIndex) {
   this.projectData.lifeCycle.phases[phaseIndex].status = id;
  }
  selectPhaseRights(id, accessLevel,phaseIndex ) {
    this.projectData.lifeCycle.phases[phaseIndex].rights.find(res => res.accessLevel == accessLevel).roles = id
    this.changeDetectionRef.markForCheck()
  }
  selectDocumentHandling(id) {
    this.projectData.onlineMode = !!id.includes(2);
    this.projectData.branchMode = !!id.includes(1);
  }

  onFileSelectedMainSettings(): void {
    this.fileInput.nativeElement.click();
  }

  onFilesSelectedMainSettings(event: any) {
    if (event.target.files.length > 0) {
      const formData = new FormData();
      const loadingStates: LoadingStateMainSettings[] = [];
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        formData.append('content', file, file.name);
        loadingStates.push({ fileName: file.name, isLoading: true });
      }
      this.attachmentApi.setAttachments(this.projectType, this.projectData.id, formData)
        .subscribe(
        (response) => {
          this.getAttachmentsMainSettings(this.projectType, this.projectData.id);
          this.isLoading.forEach((state) => state.isLoading = false);
        },
        (error) => {
          console.log(error);
          this.isLoading.forEach((state) => state.isLoading = false);
        }
      );
    } else {
      console.log('Ничего не выбрано.');
    }
    setTimeout(() => {
      this.isLoading.forEach((state) => state.isLoading = false);
      this.changeDetectionRef.detectChanges();
    }, 2000);
  }

  getAttachmentsMainSettings(parentType, parentId){
    this.attachmentApi.getAttachments(parentType, parentId)
      .subscribe(
        (res) => {
          this.attachmentsData = res['content'];
          this.changeDetectionRef.detectChanges();
        },
        (error) => {
          console.error('Ошибка при получении вложений:', error);
        }
      );
  }
  openFileToView(file) {
    console.log(file)
    const dialogRef = this.dialog.open(ViewFileComponent, {
      panelClass: 'dialog',
      data: {
        file: file,
        type: 'attachment'
      },
      width: '90vw',
      height: '90vh',
      closeOnNavigation: true,
      autoFocus: false,
    });
  }
  downloadAttachmentMainSettings(id, fileName) {
    this.attachmentApi.downloadAttachmentById(id)
      .subscribe(
      (res: Blob) => {
        const url = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(url);
      },
      (error) => {
        console.error('Ошибка загрузки файла:', error);
      }
    );
  }

  deleteAttachmentMainSettings(id) {
    this.attachmentApi.deleteAttachmentById(id)
      .subscribe((res) => {
        console.log(res);
        this.getAttachmentsMainSettings(this.projectType, this.projectData.id);
      });
  }

}
