import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class UpdateProjectListService {
  public projectListUpdate: Subject<any> = new Subject<any>()
}
