<div class="edit-document-container">
  <div class="header">
    <div class="title">
      <span class="text-18 text-600">Карточка документа</span>
    </div>
    <mat-icon
      class="item-close"
      *ngIf="true"
      svgIcon="closeIcon"
      (click)="closeDialog()"
    ></mat-icon>
  </div>
  <div class="edit-document-container-inside-block" >
    <app-dropdown
    [type]="'text'"
    [label]="'Выбрать родительский элемент'"
    [placeholder]="isPlaceholder"
    [listDropdown]="isDropListEdit"
    [isDisabled]="true">
      </app-dropdown>
    <app-input
      [label]="'Наименование'"
      [isPlaceholder]="'Введите название документа'"
      [(value)]="isNameFolder"
    ></app-input>
    <app-textarea
      [label]="'Описание'"
      [isPlaceholder]="'Введите описание документа'"
      [(value)]="isDescriptionFolder"
    ></app-textarea>
    <div class="main-info-container app-mt-10px">
      <div class="main-info-container__tittle">
        <span class="text-18" style="font-weight: 600"
          >Жизненный цикл документа</span
        >
      </div>
      <div class="main-info-container__item_row">
        <div class="main-info-container__item_col">
          <!--        <app-datepicker class="w-100" [label]="'Дата начала'"  [(value)]="projectData.lifeCycle.startPlan"></app-datepicker>-->
        </div>
        <div class="main-info-container__item_col">
          <!--        <app-datepicker class="w-100" [label]="'Дата окончания'" [(value)]="projectData.lifeCycle.endPlan" ></app-datepicker>-->
        </div>
        <div class="main-info-container__item_col"></div>
        <div
          class="main-info-container__item_col justify-content-end align-items-end"
        >
          <app-button
            titleButton="Добавить фазу"
            (click)="addPhase()"
          ></app-button>
        </div>
      </div>
      <div class="main-info-container__item_row">
        <div class="main-info-container__item_col">
          <app-progress-bar
            class="w-100"
            [isSize]="true"
            [chartData]="chartData"
          ></app-progress-bar>
        </div>
      </div>
    </div>
    <div class="phases-container">
      <div
        class="phases-container__item w-100"
        *ngFor="let phase of chartData; let i = index"
      >
        <mat-accordion togglePosition="before" class="w-100">
          <mat-expansion-panel [expanded]="phase.isEdit">
            <mat-expansion-panel-header class="app-mt-4px">
              <mat-panel-title class="text-18 text-600">
                Фаза: {{ phase.name }}</mat-panel-title
              >
              <mat-panel-description class="justify-content-end">
                <app-button
                class="app-mr-15px"
                *ngIf="!phase.isEdit"
                (click)="activatePhase(data.info.id,phase.id); $event.stopPropagation()"
                [titleButton]="phase.titleButton"
                [isRegular]="phase.id === data.info.currentPhaseId"
              ></app-button>
                <app-button
                  class="app-mr-15px"
                  *ngIf="!phase.isEdit"
                  (click)="editPhase(phase); $event.stopPropagation()"
                  [titleButton]="'Редактировать'"
                  [isRegular]="true"
                ></app-button>
                <app-button
                  class="app-mr-15px"
                  *ngIf="phase.isEdit"
                  (click)="savePhase(phase); $event.stopPropagation()"
                  [titleButton]="'Сохранить'"
                  [isRegular]="true"
                ></app-button>
                <app-button
                  [isIcon]="'trash'"
                  [isRegular]="true"
                  (click)="deletePhase(phase)"
                ></app-button>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="phases-container__content">
              <div class="phases-container__row">
                <div class="phases-container__col">
                  <app-dropdown
                    class="w-100"
                    [isDisabled]="!phase.isEdit"
                    [label]="'Статус'"
                    [placeholder]="'Выберите из списка'"
                    [listDropdown]="phaseStatusList"
                    (valueChange)="selectPhaseStatus($event, i)"
                    [defaultSelectedIds]="phase.status"
                  ></app-dropdown>
                </div>
                <div class="phases-container__col">
                  <app-datepicker
                    class="w-100"
                    [isDisabled]="!phase.isEdit"
                    [label]="'Дата начала'"
                    [(value)]="phase.startPlan"
                  ></app-datepicker>
                </div>
                <div class="phases-container__col">
                  <app-datepicker
                    class="w-100"
                    [isDisabled]="!phase.isEdit"
                    [label]="'Дата окончания'"
                    [(value)]="phase.endPlan"
                  ></app-datepicker>
                </div>
              </div>
              <div class="phases-container__row app-mt-10px">
                <div class="phases-container__col">
                  <app-textarea
                    class="w-100"
                    [isDisabled]="!phase.isEdit"
                    [maxLength]="350"
                    [label]="'Название'"
                    [(value)]="phase.name"
                  ></app-textarea>
                </div>
                <div class="phases-container__col">
                  <app-textarea
                    class="w-100"
                    [isDisabled]="!phase.isEdit"
                    [maxLength]="350"
                    [label]="'Описание'"
                    [(value)]="phase.description"
                  ></app-textarea>
                </div>
              </div>
              <div class="phases-container__row">
                <div class="phases-container__col">
                  <app-dropdown
                    class="w-100"
                    [isDisabled]="!phase.isEdit"
                    [label]="'Администрирование документа'"
                    [defaultSelectedIds]="getRights(phase.rights, 'ADMIN')"
                    [placeholder]="'Выберите из списка'"
                    [listDropdown]="roleList"
                    [isMultiple]="true"
                    (valueChange)="selectPhaseRights($event, 'ADMIN', i)"
                  ></app-dropdown>
                </div>
                <div class="phases-container__col">
                  <app-dropdown
                    class="w-100"
                    [isDisabled]="!phase.isEdit"
                    [label]="'Исправление документа'"
                    [defaultSelectedIds]="getRights(phase.rights, 'EDIT')"
                    [placeholder]="'Выберите из списка'"
                    [listDropdown]="roleList"
                    [isMultiple]="true"
                    (valueChange)="selectPhaseRights($event, 'EDIT', i)"
                  ></app-dropdown>
                </div>
              </div>
              <div class="phases-container__row app-mt-10px">
                <div class="phases-container__col">
                  <app-dropdown
                    class="w-100"
                    [isDisabled]="!phase.isEdit"
                    [label]="'Чтение документа'"
                    [defaultSelectedIds]="getRights(phase.rights, 'READ')"
                    [placeholder]="'Выберите из списка'"
                    [listDropdown]="roleList"
                    [isMultiple]="true"
                    (valueChange)="selectPhaseRights($event, 'READ', i)"
                  ></app-dropdown>
                </div>
                <div class="phases-container__col">
                  <app-dropdown
                    class="w-100"
                    [isDisabled]="!phase.isEdit"
                    [label]="'Согласование документа'"
                    [defaultSelectedIds]="getRights(phase.rights, 'APPROVE')"
                    [placeholder]="'Выберите из списка'"
                    [listDropdown]="roleList"
                    [isMultiple]="true"
                    (valueChange)="selectPhaseRights($event, 'APPROVE', i)"
                  ></app-dropdown>
                </div>
              </div>
              <div class="phases-container__row app-mt-10px app-mb-20px">
                <div class="phases-container__col">
                  <app-dropdown
                    class="w-100"
                    [isDisabled]="!phase.isEdit"
                    [label]="'Смена статуса документа'"
                    [defaultSelectedIds]="
                      getRights(phase.rights, 'CHANGE_STATUS')
                    "
                    [placeholder]="'Выберите из списка'"
                    [listDropdown]="roleList"
                    [isMultiple]="true"
                    (valueChange)="selectPhaseRights($event, 'CHANGE_STATUS', i)"
                  ></app-dropdown>
                </div>
                <div class="phases-container__col"></div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <div class="edit-document-container-download">
      <div class="edit-document-container-download-row">
        <span class="text-16" style="font-weight: 600">Загрузить файлы</span>
        <app-button
          (click)="onFileSelected()"
          [titleButton]="'Загрузить'"
          [isRegular]="true"
        ></app-button>
      </div>
      <div class="edit-document-container-files_block">
        <input #fileInput type="file" style="display: none;"  multiple (change)="onFilesSelected($event)">
              <div *ngIf="attachmentsData && attachmentsData.length > 0">
                <div class="edit-document-container-files_block-scroll-item" *ngFor="let attachment of attachmentsData; let i = index">
                  <div class="edit-document-container-files_block-scroll-item-name">
                    <mat-icon *ngIf="true" svgIcon="fileIcon"></mat-icon>
                    <span class="fileName text-14 text-400">{{attachment.name}}</span>
                  </div>
                  <div class="loading-indicator" *ngIf="isLoading[i]">
                    <div>
                      <div class="fill" [@progressAnimation]></div>
                    </div>
                  </div>
                  <!-- <span *ngIf="!isLoading[i]" class="fileName text-14 text-400">1.2 Мб</span> -->
                  <span *ngIf="!isLoading[i]" class="fileName text-14 text-400">{{attachment.createdDate | date: 'dd.MM.yyyy'}}</span>
                  <span *ngIf="!isLoading[i]" class="fileName text-14 text-400">{{attachment.creator.lastName + ' ' + attachment.creator.firstName }}</span>
                  <div class="edit-document-container-files_block-scroll-item-files_row">
                    <mat-icon
                      class="size"
                      *ngIf="!isLoading[i]"
                      svgIcon="openFileIcon"
                      (click)="openFileToView(attachment)"
                    ></mat-icon>
                    <mat-icon
                      class="size"
                      *ngIf="!isLoading[i]"
                      svgIcon="downloadFileIcon"
                      (click)="downloadAttachment(attachment.id, attachment.name)"
                    ></mat-icon>
                    <app-button *ngIf="!isLoading[i]" (click)="deleteAttachment(attachment.id)" [isRegular]="true" [isIcon]="'trash'"></app-button>
                  </div>
                </div>
              </div>
      </div>
    </div>
    <div class="edit-document-container-footer">
      <app-button
        [titleButton]="'Отменить'"
        [isSolid]="false"
        [isRegular]="true"
        (click)="closeDialog()"
        >Отмена</app-button
      >
      <app-button
        (click)="routeToVersionHistory()"
        [titleButton]="'Открыть историю версий'"
        [isSolid]="true"
        [isRegular]="false"
      ></app-button>
      <app-button *ngIf="this.data.info.combineSettings"
        (click)="reMergeDocument(this.data.info)"
        [titleButton]="'Пересобрать документ'"
        [isSolid]="true"
        [isRegular]="false"
      ></app-button>
      <app-button
        (click)="saveDocument()"
        [titleButton]="'Сохранить'"
        [isSolid]="true"
        [isRegular]="false"
      ></app-button>
    </div>
  </div>
</div>
