import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {NoticeListComponent} from "./components/notice-list/notice-list.component";
import {NoticeApi} from "../../shared/api/notice.api";
import {CurrentUserService} from "../../shared/services/current-user.service";
import {UserApi} from "../../shared/api/user.api";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-notice',
  standalone: true,
  imports: [NoticeListComponent, NgIf],
  templateUrl: './notice.component.html',
  styleUrl: './notice.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoticeComponent implements OnInit, OnDestroy{

  noticeList: any[];
  size: number = 15;
  currentPage = 0;
  totalItems: number;
  currentUserId: number;

  constructor(
    private noticeApi: NoticeApi,
    private userApi: UserApi,
    private changeDetector: ChangeDetectorRef

  ) {
  }
  ngOnInit() {
   this.getUserInfo()
  }

  getUserInfo() {
    this.userApi.getUserInfo()
      .subscribe((res: any) => {
        this.getNoticeList(res.id)
        this.currentUserId = res.id;
      })
  }
  getNoticeList(userId) {
    this.noticeApi.getNotificationListByUserId(userId, this.size, this.currentPage)
      .subscribe((res:any) => {
       this.noticeList = res.content;
       this.totalItems = res.totalElements;
       this.changeDetector.markForCheck();
      })
  }

  updateStatus(){
    const mass: any = this.noticeList.map((res:any) => res.id)
    this.noticeApi.updateStatus(mass)
      .subscribe(() => {})
  }

  changePage(page: number) {
    this.currentPage = page - 1;
    this.getNoticeList(this.currentUserId)
  }

  ngOnDestroy() {
    this.updateStatus();
  }
}



