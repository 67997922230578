import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {MatIcon, MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {Router} from "@angular/router";
import {NgxPaginationModule, PaginationInstance} from "ngx-pagination";

@Component({
  selector: 'app-notice-list',
  standalone: true,
  imports: [
    NgForOf,
    NgClass,
    MatIcon,
    NgIf,
    NgxPaginationModule
  ],
  templateUrl: './notice-list.component.html',
  styleUrl: './notice-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoticeListComponent implements OnInit{
  @Input() noticeList: any = [];
  @Input() size: number;
  @Input() totalItems: number;
  @Output() currentPage = new EventEmitter<number>();
  page: number = 1;

  config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: 5,
    currentPage: 1,
    totalItems: 10
  };

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
  ) {}
  ngOnInit() {
    this.matIconRegistry.addSvgIcon(
      'linkIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/search-link.svg'
      )
    );
    this.config.itemsPerPage = this.size;
    this.config.totalItems = this.totalItems;
  }
  routeToEntity(entity) {
   if(entity.sourceObjectType === 'Task') {
     this.router.navigate(['/project/' + entity.projectId + '/task-tracker'], {queryParams: {taskId: entity.sourceObjectId}})
   }
   if(entity.sourceObjectType === 'Comment') {
     this.router.navigate(['/project/' + entity.projectId + '/work-material/document/' + entity.documentId])
   }
    if(entity.sourceObjectType === 'Document') {
      this.router.navigate(['/project/' + entity.projectId + '/work-material'], {queryParams: {documentId: entity.sourceObjectId}})
    }
  }
}
