import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {MainSettingsComponent} from "./main-settings.component";
import {MainSettingsEditComponent} from "./components/main-settings-edit/main-settings-edit.component";

const routes: Routes = [
  {
    path:'',
    component: MainSettingsComponent,

  },
  {
    path: ':edit',
    component: MainSettingsEditComponent
  }
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainSettingsRoutingModule {}
