export function generateProjectMock() {
  return {
   id: null,
   code: '',
   name: '',
   description: '',
   type: '',
    responsibleUser: {
     id: 1
    },
    startFact: new Date(),
    endPlan: '',
    endFact: null,
    onlineMode: true,
    branchMode: true,
    lifeCycle: {
      startPlan: '',
      endPlan: '',
      startFact: new Date(),
      endFact: null,
      phases: []
    },
    members: {},
    documentHandlingModeArray: [true,true]
  }
}
