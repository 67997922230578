import {KeycloakService} from "keycloak-angular";
import {CommonService} from "../shared/services/common-service";
import {environment} from "../../environments/evironment";

export function initializeKeycloak(keycloak: KeycloakService, commonService: CommonService): () => Promise<boolean> {
  return () => getDefaultConfig(keycloak, commonService);
}
function getDefaultConfig(keycloak: KeycloakService, commonService: CommonService): Promise<boolean> {
  const currentURL = window.location.origin + '/config/front-kc-config.json';
  return commonService.keycloakConfig(currentURL).toPromise()
    .then((res: any) => {
      const authUrl = res.authUrl;
      const realm = res.realm;
      const clientId = res.clientId;
      const checkLoginIframe = Boolean(res.checkLoginIframe);
      const checkLoginIframeInterval = +res.checkLoginIframeInterval;
      const loadUserProfileAtStartUp = Boolean(res.loadUserProfileAtStartUp);
      return keycloak.init({
        config: {
          url: authUrl,
          realm: realm,
          clientId: clientId,
        },
        initOptions: {
          checkLoginIframe: checkLoginIframe,
          checkLoginIframeInterval: checkLoginIframeInterval,
        },
        loadUserProfileAtStartUp: loadUserProfileAtStartUp,
      });
    })
    .catch((err) => {
      console.log('Error ', err);
      if (environment.defaultConfig) {
        return keycloak.init(defaultConfig());
      }
      return false;
    });
}

function defaultConfig() {
  return {
    config: {
      url: environment.url,
      realm: environment.realm,
      clientId: environment.clientId,
    },
    initOptions: {
      checkLoginIframe: true,
      checkLoginIframeInterval: 25,
    },
    loadUserProfileAtStartUp: true,
  };
}
