import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  HostListener
} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter, Subscription} from "rxjs";
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

export interface DropDown {
  id: any,
  name: string,
  router?: string,
};

@Component({
    imports: [
        MatIconModule,
        CommonModule,
        FormsModule
    ],
    standalone: true,
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrl: './dropdown.component.scss',
})

export class DropDownComponent implements OnInit, OnChanges {
    @Input() type: string = 'text'; // text / button
    @Input() label: string = 'Label'; // Only to type text dropdown
    @Input() placeholder: string = 'Placeholder'; // If type text to placeholder or type button to label
    @Input() isMultiple: boolean = false;
    @Input() isDisabled: boolean = false;
    @Input() listDropdown: DropDown[] | null = []; // Array for item dropdown
    @Input() defaultSelectedNames: string[] = [];
    @Input() defaultSelectedIds: any[] = [];
    @Output() valueChange = new EventEmitter<any>();

    public isResult: boolean = false;
    public selectedId: number | null = null;
    public selectedIdMultiple: number[] = [];
    public listDropdownPlaceholder: { id: string | number, name: string }[] = [];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {

    this.matIconRegistry.addSvgIcon(
        'arrow-right',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-right.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'not-select-checkbox',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/not-select-checkbox.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'select-checkbox',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/select-checkbox.svg')
    );

    this.setDefaultSelectedItems();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Проверяем, изменился ли список listDropdown
    if (changes['listDropdown']) {
      this.setDefaultSelectedItems();
    }
  }

  setDefaultSelectedItems() {
    if (this.listDropdown && this.listDropdown.length > 0) {
      this.listDropdown.forEach(item => {
        if (this.defaultSelectedNames && this.defaultSelectedNames.includes(item.name) && !this.listDropdownPlaceholder.some(el => el.name === item.name)) {
          this.listDropdownPlaceholder.push(item);
          this.selectedIdMultiple.push(item.id);
          this.placeholder = item.name;
        }
        if (this.defaultSelectedIds && this.defaultSelectedIds.includes(item.id) && !this.listDropdownPlaceholder.some(el => el.id === item.id)) {
          this.listDropdownPlaceholder.push(item);
          this.selectedIdMultiple.push(item.id);
          this.placeholder = item.name;
        }
      });
    }
  }

  isItemSelected(item: any): boolean {
    return this.listDropdownPlaceholder.some(el => el.name === item.name);
  }

  selectItem(item: any): void {
    if (!this.isDisabled) {

      if(this.isMultiple) {
        if (this.selectedIdMultiple.includes(item.id)) {
          this.selectedIdMultiple.splice(this.selectedIdMultiple.indexOf(item.id), 1)
        } else {
          this.selectedIdMultiple.push(item.id);
        }
        this.valueChange.emit(this.selectedIdMultiple);

        if (this.type == 'text') {
          const index = this.listDropdownPlaceholder.findIndex(el => el.name === item.name);
          if (index === -1) {
            this.listDropdownPlaceholder.push({
              id: item.id,
              name: item.name,
            });
          } else {
            this.listDropdownPlaceholder = this.listDropdownPlaceholder.filter(el => el.name !== item.name);
          }
        }
      } else {
        this.selectedId = item.id;
        this.valueChange.emit(this.selectedId);
        if(this.type == 'text') {
          this.placeholder = item.name
        }

        this.isResult = false;
      }

    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.isResult) {
      return;
    }
    const target = event.target as HTMLElement;
    if (!target.closest('.dropdown-block')) {
      this.isResult = false;
    }
  }
}
