import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})

export class NoticeApi {
  apiUrl = '/skrndapp'
  headers: HttpHeaders;
  token: string|null;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('view_auth_token');
    this.headers = new HttpHeaders().append('Authorization', `Basic ${this.token}`)
  }

  getNotificationList() {
    return this.http.get(`${this.apiUrl}/notifications`, {
      headers: this.headers
    })
  }
  getNotificationListByUserId(userId, size, page) {
    return this.http.get(`${this.apiUrl}/notificationsByTargetUserId/${userId}`, {
      headers: this.headers,
        params: {
          size: size,
          page: page
      }
    },
   )
  }

  getCountNotification() {
    return this.http.get(`${this.apiUrl}/getCountNotification`, {
      headers: this.headers
    })
  }

  updateStatus(id) {
    return this.http.post(`${this.apiUrl}/setStatusNotification`, id, {
        headers: this.headers,
      }
      )
  }
}
