import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {MatTable, MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {CommonModule} from '@angular/common';
import {CdkDrag, CdkDragDrop, CdkDropList, DragDropModule, moveItemInArray,} from '@angular/cdk/drag-drop';
import {BarChartComponent} from '../../core/ui-components/charts/bar-chart/bar-chart.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule, MatIconRegistry} from "@angular/material/icon";
// import {SpaceApi} from "../../shared/api/space.api";
import {MainProjectLayoutService} from "../main-project-layout/main-project-layout.service";
import {AddTaskComponent} from "./modals/add-task/add-task.component";
import {MatDialog} from "@angular/material/dialog";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
// import {EditSpaceComponent} from "../edit-space/edit-space.component";
import {EditTaskComponent} from "./modals/edit-task/edit-task.component";
import {EditDocumentComponent} from "../work-material/modal/edit-document/edit-document.component";
import {AddDocumentComponent} from "../work-material/modal/add-document/add-document.component";
import {DocumentApi} from "../../shared/api/document.api";
import {TaskTrackerApi} from '../../shared/api/task-tracker.api';
import { DropDown } from '../../core/ui-components/dropdown/dropdown.component';
import { ImportDocumnetsComponent } from '../work-material/modal/import-documents/import-documents.component';
import { ChooseComponent } from '../work-material/modal/choose/choose.component';
import { ProgressBarComponent } from '../../core/ui-components/progress-bar/progress-bar.component';
import { DomSanitizer } from '@angular/platform-browser';
import {MatProgressSpinner} from "@angular/material/progress-spinner";

export interface TaskTrackerInterface {
  id: number,
  name: string,
  description: string,
  author: string,
  date: string,
  phase: [],
  expand: boolean;
  sortOrder: any;
  parentProjectId: number;
  type:string;
  parentTask: any;
  children?: TaskTrackerInterface[];
}

export const ELEMENT_DATA: TaskTrackerInterface[] = [
  {
    id: 1,
    name: '1',
    date: '1',
    phase: [],
    author: '1',
    expand: false,
    description: '1',
    type:'',
    sortOrder: null,
    parentTask: null,
    parentProjectId: 1,
    children: [
    ]
  },
];

@Component({
  imports: [
    CommonModule,
    MatTableModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    DragDropModule,
    CdkDropList,
    CdkDrag,
    BarChartComponent,
    ImportDocumnetsComponent,
    ChooseComponent,
    ProgressBarComponent,
    MatProgressSpinner
  ],
  standalone: true,
  selector: 'task-tracker-table',
  templateUrl: './task-tracker-table-2.component.html',
  styleUrls: ['./task-tracker.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TaskTrackerTable implements OnInit, AfterViewInit {
  @Input() isDropList: DropDown[] | null = [];
  columnsToDisplay = ['id','name', 'weight', 'symbol',];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: TaskTrackerInterface | null;
  @ViewChild(MatTable, { read: ElementRef }) private matTableRef!: ElementRef;
  columns: any[] = [
    { header: '№', field: 'id', width: 1 },
    { header: 'Наименование задачи', field: 'name', width: 25 },
    { header: 'Связанный документ', field: 'parentDoc', width: 25 },
    { header: 'Приоритет', field: 'priority', width: 1 },
    { header: 'Дата постановки задачи', field: 'startPlan', width: 15 },
    { header: 'Дедлайн', field: 'endPlan', width: 40 },
    { header: 'Статус задачи', field: 'status', width: 30 },
  ];
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<TaskTrackerInterface>(ELEMENT_DATA);
  colorList = ['#00D359','#FFB608','#FF4F12','#797E8B']
  pressed = false;
  currentResizeIndex!: number;
  startX!: number;
  startWidth!: number;
  isResizingRight!: boolean;

  resizableMousemove!: () => void;
  resizableMouseup!: () => void;
  id: any;
  projectId: any;
  isLoaded: boolean = false;
  taskIdFromQueryParams: any = null;

  public counterPerPage: number = 15; // Количество записей для показа
  public counterNowPages: number = 1; // Текущая страница
  public conterAllPages: number = 1; // Всего страниц


  protected subscriptions: Subscription[] = [];
  constructor(
    private renderer: Renderer2,
    private changeDetectionRef: ChangeDetectorRef,
    private taskTrackerApi: TaskTrackerApi,
    private mainService: MainProjectLayoutService,
    private dialog:MatDialog,
    private router: Router,
    private documentApi: DocumentApi,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private route: ActivatedRoute
  ) {
    mainService.id.subscribe((res) => {
      console.log(res)
      this.projectId = res;
    })
}
//
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if(params){
        this.taskIdFromQueryParams = params['taskId'];
      }
    })
    this.setDisplayedColumns();
    if(this.projectId) {
      this.getTaskTrackersFromProject(this.projectId)
    } else{
      this.getUserTaskList();
    }
    this.matIconRegistry.addSvgIcon(
      'arrow-left',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-left.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'arrow-right',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-right.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'arrow-right-g',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-right-g.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'arrowDropDown',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/ArrowDropDown.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'folder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/folder.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/edit.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'plus',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/plus.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'file',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/file.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'arrow-down',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-down.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'importIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/import.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'lowIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/low.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'mediumIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/medium.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'highIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/high.svg'
      )
    );
    // this.getTaskById(this.id);
  }

  ngAfterViewInit() {
    if(this.matTableRef) {
      this.setTableResize(this.matTableRef.nativeElement.clientWidth);
    }
  }

  getUserTaskList() {
    this.isLoaded = false;
  this.taskTrackerApi.getTaskTrackerList()
    .subscribe((res: any) => {
      this.dataSource.data = res.content.map((des) => {
        console.log(this.dataSource.data);
        if (!this.isDropList?.includes(des.id)) {
          this.isDropList?.push({id: des.id, name: des.name})}
        return this.expandAll(des)
      }, (error)=>{console.log(error)})
      this.isLoaded = true;
      this.changeDetectionRef.markForCheck();

    })
  }
  setTableResize(tableWidth: number) {
    let totWidth = 0;
    this.columns.forEach((column) => {
      totWidth += column.width;
    });
    const scale = (tableWidth - 5) / totWidth;
    this.columns.forEach((column) => {
      column.width *= scale;
      this.setColumnWidth(column);
    });
  }

  setDisplayedColumns() {
    this.columns.forEach((column, index) => {
      column.index = index;
      this.displayedColumns[index] = column.field;
    });
  }

  onResizeColumn(event: any, index: number) {
    this.checkResizing(event, index);
    this.currentResizeIndex = index;
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = event.target.parentElement.clientWidth;
    event.preventDefault();
    this.mouseMove(index);
  }

  private checkResizing(event: any, index: number) {
    const cellData = this.getCellData(index);
    if (
      index === 0 ||
      (Math.abs(event.pageX - cellData.right) < cellData.width / 2 &&
        index !== this.columns.length - 1)
    ) {
      this.isResizingRight = true;
    } else {
      this.isResizingRight = false;
    }
  }

  private getCellData(index: number) {
    const headerRow =
      this.matTableRef.nativeElement.children[0].querySelector('tr');
    const cell = headerRow.children[index];
    return cell.getBoundingClientRect();
  }

  mouseMove(index: number) {
    this.resizableMousemove = this.renderer.listen(
      'document',
      'mousemove',
      (event) => {
        if (this.pressed && event.buttons) {
          const dx = this.isResizingRight
            ? event.pageX - this.startX
            : -event.pageX + this.startX;
          const width = this.startWidth + dx;
          if (this.currentResizeIndex === index && width > 50) {
            this.setColumnWidthChanges(index, width);
          }
        }
      }
    );
    this.resizableMouseup = this.renderer.listen(
      'document',
      'mouseup',
      (event) => {
        if (this.pressed) {
          this.pressed = false;
          this.currentResizeIndex = -1;
          this.resizableMousemove();
          this.resizableMouseup();
        }
      }
    );
  }

  setColumnWidthChanges(index: number, width: number) {
    const orgWidth = this.columns[index].width;
    const dx = width - orgWidth;
    if (dx !== 0) {
      const j = this.isResizingRight ? index + 1 : index - 1;
      const newWidth = this.columns[j].width - dx;
      if (newWidth > 50) {
        this.columns[index].width = width;
        this.setColumnWidth(this.columns[index]);
        this.columns[j].width = newWidth;
        this.setColumnWidth(this.columns[j]);
      }
    }
  }

  setColumnWidth(column: any) {
    const columnEls = Array.from(
      document.getElementsByClassName('mat-column-' + column.field)
    ) as HTMLDivElement[];
    columnEls.forEach((el: HTMLDivElement) => {
      el.style.width = column.width + 'px';
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if(this.matTableRef) {
      this.setTableResize(this.matTableRef.nativeElement.clientWidth);
    }
  }

  onDrop(event: CdkDragDrop<any[], any[], any>) {
    const dataArray = this.dataSource.data
    moveItemInArray( dataArray, event.previousIndex, event.currentIndex);
    this.dataSource.data = dataArray.map((res, index) => {
      res.sortOrder = index
      return res;
    }).slice()
    // this.saveSortSpaces(this.dataSource.data)
    this.changeDetectionRef.markForCheck();
  }
  onDropChild(event: CdkDragDrop<any[], any[], any>, mass) {
    moveItemInArray( mass, event.previousIndex, event.currentIndex);
  }
  changeExpend(element) {
    element.expand = !element.expand;
    this.changeDetectionRef.markForCheck()
  }
  getTaskTrackersFromProject(projectId:any) {
    this.isLoaded = false;
    this.dataSource.data = ELEMENT_DATA
    this.isDropList = [];
    this.taskTrackerApi.getTaskTrackerByProjectId(projectId)
      .subscribe((res: any) => {
        if (res) {
          this.dataSource.data = res.map((des) => {
            if (!this.isDropList?.includes(des.id)) {
              this.isDropList?.push({id: des.id, name: des.name})}
            return this.expandAll(des)
          })
        }
      }, (error)=>{console.log(error)})
    this.isLoaded = true;
    if(this.taskIdFromQueryParams) {
      let taskForOpen = this.dataSource.data.find(res => res.id == this.taskIdFromQueryParams)
      this.editTask(taskForOpen, taskForOpen?.parentTask?.id || null)
    }
    this.changeDetectionRef.markForCheck();
  }
  getTaskByIdForChild(parentTaskId:any) {
    let arr:any = []
    this.taskTrackerApi.getTaskTrackerByParentId(parentTaskId)
      .subscribe((res: any) => {
        res.map(des => {
           des.children = []
          //  if (des.type === 'TASK') {
             des.author = '-'
             des.date = '-'
             des.phase = []
             des.expand = false;
          //  }
          if (des.type === 'DOCUMENT') {
             des.author = des.creator.firstName + ' ' +  des.creator.lastName
             des.date = new Date(des.lifeCycle.endPlan).toLocaleDateString("en-US")
            let arr:any = []
            for(let i = 0; i < des?.lifeCycle.phases.length; i++) {
              arr.push({
                color: this.colorList[i],
                data: des.lifeCycle.phases[i]
              })
            }
            des.phase = arr
          }
           arr.push(des);
         });
        this.changeDetectionRef.markForCheck()
        })
    return arr;
  }

  addTask(element) {
    const dialogRef = this.dialog.open(AddTaskComponent, {
      panelClass: 'dialog',
      data: {
        info: element,
        parentProjectId: this.projectId,
        parenTaskId: element.id,
      },
      width: '900px',
      height: '650px',
      closeOnNavigation: true,
      autoFocus: false,
    });
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((_) => {
        if(_) {
          this.saveTask(_)
          // if(element.type == 'PROJECT')
          // }
         }
      }),
      this.router.events.subscribe((_) => dialogRef.close()),
    );
  }
  editTask(element, parentId) {
    const dialogRef = this.dialog.open(EditTaskComponent, {
      panelClass: 'dialog',
      data: {
        info: element,
        parentTaskId: parentId,
        parentProjectId: this.projectId,
      },
      width: '900px',
      height: '650px',
      closeOnNavigation: true,
      autoFocus: false,
    });
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((_) => {
        if (_) {
          if(this.taskIdFromQueryParams){
            this.taskIdFromQueryParams = null;
          }
          this.saveTask(_);
      }
      }),
      this.router.events.subscribe((_) => dialogRef.close()),
    );
  }
  saveTask(task) {
   this.taskTrackerApi.saveTaskTracker(task)
     .subscribe(() => {
       if(this.projectId) {
         this.getTaskTrackersFromProject(this.projectId)
       } else{
         this.getUserTaskList();
       }
     })
  }
  documentAction(element) {
    if (element.type == 'DOCUMENT') {
       this.navigateToDocument(element)
    }
    // if (element.type == 'TASK') {
      // this.createDocument(element)
    // }
  }
  editAction(element, parentId) {
    // if (element.type == 'TASK') {
      this.editTask(element, parentId)
    // }
    // if (element.type == 'DOCUMENT') {
    //   this.editDocument(element, parentId)
    // }
  }
  // createDocument(element) {
  //   const dialogRef = this.dialog.open(AddDocumentComponent, {
  //     panelClass: 'dialog',
  //     data: {
  //       info: element,
  //     },
  //     width: '78%',
  //     height: '70%',
  //     closeOnNavigation: true,
  //     autoFocus: false,
  //   });
  //   this.subscriptions.push(
  //     dialogRef.afterClosed().subscribe((_) => {
  //       if(_) {
  //          this.saveDocument(_)
  //       }
  //     }),
  //     this.router.events.subscribe((_) => dialogRef.close()),
  //   );
  // }
  editDocument(element, parentId) {
    const dialogRef = this.dialog.open(EditDocumentComponent, {
      panelClass: 'dialog',
      data: {
        info: element,
        parentId: parentId
      },
      width: '90%',
      height: '90%',
      closeOnNavigation: true,
      autoFocus: false,
    });
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((_) => {
        if(_) {
            this.saveDocument(_)
        }
      }),
      this.router.events.subscribe((_) => dialogRef.close()),
    );
  }
  importDocuments(element) {
    const dialogRef = this.dialog.open(ImportDocumnetsComponent, {
      panelClass: 'dialog',
      data: {
        info: element,
        dropList: this.isDropList,
        cardId: element.id
      },
      width: '690px',
      height: '671px',
      closeOnNavigation: true,
      autoFocus: false,
    });
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((_) => {
        if(_) {
           this.importFiles(_.spaceId, this.projectId, _)
        }
      }),
      this.router.events.subscribe((_) => dialogRef.close()),
    );
  }
  importFiles(spaceId, cardId, document) {
    this.documentApi.importFiles(spaceId, cardId, document.content)
      .subscribe((res) => {
        this.getTaskTrackersFromProject(this.projectId)
      })
  }
  navigateToDocument(element) {
    this.router.navigate([`/document/${element.id}`]).then(r =>  {})
  }
  saveDocument(document) {
     this.documentApi.saveDocument(document)
       .subscribe(() => {
       })
  }
  // saveSortSpaces(list) {
  //    this.spaceApi.saveSortSpace(list)
  //      .subscribe((res) => {
  //        console.log(res)
  //      })
  // }
  expandAll(mass) {
    mass.expand = true;
    if( mass.children && mass.children.length > 0) {
      mass.children.map((res) => {
        this.expandAll(res)
        return res;
      })
    }
    return mass;
  }
  compareTime(start, end){

    let startTime = new Date(start);
    let endTime = new Date(end);
    let fullDate = Date.now();
    let currentDateString = new Date(fullDate).toDateString();
    let currentDate = new Date(currentDateString);


    if (currentDate.getTime() > endTime.getTime()){
      return 1
    }
    if (currentDate.getTime() <= endTime.getTime()){
      return 3
    }


    return false
  }
  routeToDoc(parentDocId){
    this.router.navigate([`project/${this.projectId}/work-material/document/${parentDocId}`])
  }
}
