import {Component, EventEmitter, OnDestroy, OnInit, Input, Output} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter, Subscription} from "rxjs";
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SearchtApi } from '../../../shared/api/search.api';
import { WorkMaterialComponent } from '../../../pages/work-material/work-material.component';
import { NotificationsService } from '../notification/services/notification.service';

@Component({
    imports: [
        MatIconModule,
        CommonModule,
        FormsModule,
    ],
    standalone: true,
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrl: './search.component.scss',
})

export class SearchComponent implements OnInit {
  @Input() isDisabled: boolean = false;
  @Input() isPlaceholder: string = '';

  public searchValue: string = '';
  public resultSearch: any[]=[];
  public isResult: boolean = false;
  @Output() searchData = new EventEmitter<{ searchValue: string; resultSearch: any[] }>();

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private searchApi: SearchtApi,
    private notification: NotificationsService,
  ) {}
  onInput(event: Event) {
    this.searchValue = (event.target as HTMLInputElement).value;
  }
  onEnter(event: Event) {
    const keyboardEvent = event as KeyboardEvent;
    if (keyboardEvent.key === 'Enter') {
      if (this.searchValue.trim().length >= 3) {
        this.searchData.emit({ searchValue: this.searchValue, resultSearch: this.resultSearch });
      } else {
        this.notification.message$.next('Пождалуйста введи не менее 3-х символов')
      }
    }
}

  dataSearch() {
    this.searchApi.searchData(this.searchValue).subscribe(response => {
    this.resultSearch = response as any[];
    this.isResult = true;
    });
  }

  ngOnInit() {
    this.matIconRegistry.addSvgIcon(
        'search',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/search.svg')
    );
  }
}
