import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {ModalBaseComponent} from "../../../../core/base/modal.base";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormField, MatFormFieldModule, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput, MatInputModule} from "@angular/material/input";
import {CommonModule} from "@angular/common";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UserApi} from "../../../../shared/api/user.api";
import {BarChartComponent} from "../../../../core/ui-components/charts/bar-chart/bar-chart.component";
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerModule,
  MatDatepickerToggle
} from "@angular/material/datepicker";
import {elementAt, Subscription} from "rxjs";
import {Router} from "@angular/router";
import {MatNativeDateModule, provideNativeDateAdapter} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {DocumentApi} from "../../../../shared/api/document.api";
import {MainProjectLayoutService} from "../../../main-project-layout/main-project-layout.service";
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ButtonComponent } from '../../../../core/ui-components/button/button.component';
import { DropDown, DropDownComponent } from '../../../../core/ui-components/dropdown/dropdown.component';
import { InputComponent } from '../../../../core/ui-components/input/input.component';
import { TextareaComponent } from '../../../../core/ui-components/textarea/textarea.component';
import {ProgressBarComponent} from "../../../../core/ui-components/progress-bar/progress-bar.component";
import {DatepickerComponent} from "../../../../core/ui-components/datepicker/datepicker.component";
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader, MatExpansionPanelTitle
} from "@angular/material/expansion";
import moment from 'moment';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { AttachmentApi } from '../../../../shared/api/attachment.api';
import {NotificationsService} from "../../../../core/ui-components/notification/services/notification.service";

interface Attachment {
  id: number;
  fileUuid: string;
  name: string;
  createdDate: string;
  updateDate: string | null;
  creator: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  };
  updateUser: string | null;
  parentType: string;
  parentId: number;
}

interface LoadingState {
  fileName: string;
  isLoading: boolean;
}

@Component({
  selector: 'app-edit-document',
  standalone: true,
  imports: [
    FormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    CommonModule,
    BarChartComponent,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatSelectModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatDatepickerModule,
    MatDatepickerInput,
    MatNativeDateModule,
    MatIconModule,
    ButtonComponent,
    TextareaComponent,
    DropDownComponent,
    InputComponent,
    ProgressBarComponent,
    DatepickerComponent,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelDescription,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle
  ],
  providers: [
    MatDatepickerModule,
    provideNativeDateAdapter()
  ],
  templateUrl: './edit-document.component.html',
  styleUrl: './edit-document.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('progressAnimation', [
      state('start', style({
        width: '100%',
        transform: 'translateX(100%)'
      })),
      state('end', style({
        width: '0%',
        transform: 'translateX(0)'
      })),
      transition('end => start', [
        animate('2s')
      ])
    ])
  ]
})
export class EditDocumentComponent extends ModalBaseComponent implements OnInit {

  public isNameFolder: string;
  public isDescriptionFolder: string;
  public isDropListEdit: DropDown[] | null = [];
  public isDropFileType: DropDown[] | null = [];
  public selectedId: number | null = null;
  public isPlaceholder: string = 'Начните вводить название элементов или выберите из ниженперечисленных';
  @ViewChild('fileInput') fileInput!: ElementRef;

  constructor(
    override dialogRef: MatDialogRef<EditDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userApi: UserApi,
    private attachmentApi: AttachmentApi,
    private dialog:MatDialog,
    private changeDetectionRef: ChangeDetectorRef,
    private router: Router,
    private documentApi: DocumentApi,
    private mainService: MainProjectLayoutService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private notificationService: NotificationsService
  ) {
    super(dialogRef);
    mainService.id.subscribe((res) => {
      this.id = res;
    })
  }
  id;
  protected subscriptions: Subscription[] = [];
  userInfo: string;
  chartData: any = [];
  attachmentsData: Attachment[] = [];
  roleList: any = [];
  selectedFiles: File[] = [];
  isLoading: LoadingState[] = [];
  phaseStatusList: any[] = [
    {
      name:'PLAN',
      id: 'PLAN'
    },
    {
      name:'IN_PROGRESS',
      id: 'IN_PROGRESS'
    },
    {
      name:'COMPLETED',
      id: 'COMPLETED'
    },
  ]

  async ngOnInit() {

    console.log(this.data.info);

    this.matIconRegistry.addSvgIcon(
      'closeIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/close.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'fileIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/file.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'openFileIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/openFile.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'downloadFileIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/downloadFile.svg'
      )
    );
    this.userApi.getUserInfo()
    .subscribe((user: any) => {
      this.userInfo = `${user.lastName} ${user.firstName}`;
    });
    this.isNameFolder = this.data.info.name
    this.isDescriptionFolder = this.data.info.description
    this.isDropListEdit = this.data.dropList;
    this.isDropFileType = this.data.dropTypeFile;
    this.selectedId = this.data.parentId;
    this.chartData = this.data.info.lifeCycle.phases
    this.chartData.forEach(phase => {
      phase.titleButton = phase.id === this.data.info.currentPhaseId ? 'Активировано' : 'Активировать';
      phase.isRegular = phase.id === this.data.info.currentPhaseId;
  });
    this.getRoleList();
    this.updatePlaceholder();
    await this.getAttachments(this.data.info.type, this.data.info.id)
  }

  updatePlaceholder() {
    if (this.isDropListEdit && this.data.parentId) {
      const item = this.isDropListEdit.find(item => String(item.id) === String(this.data.parentId));
      if (item) {
        this.isPlaceholder = item.name;
      }
    }
  }
  saveDocument() {
    let document = {
      id: this.data.info.id,
      spaceId: this.data.parentId,
      parentType: this.data.parentType,
      name: this.isNameFolder,
      description: this.isDescriptionFolder,
      lifeCycle: {
        phases: this.chartData.map(res => {
          if ( moment(res.startPlan,'DD.MM.YYYY').format() !== 'Invalid date') {
            res.startPlan = moment(res.startPlan,'DD.MM.YYYY').format();
          }
          if ( moment(res.endPlan,'DD.MM.YYYY').format() !== 'Invalid date') {
            res.endPlan = moment(res.endPlan,'DD.MM.YYYY').format();
          }
          return res;
        }),
        endPlan: this.data.info.lifeCycle.endPlan,
        startPlan: this.data.info.lifeCycle.startPlan,
        id: this.data.info.lifeCycle.id,
      },
      editDocument: true
  }
    this.dialogRef.close(document)
  }
  getRoleList() {
    this.userApi.getRoleList()
      .subscribe((res:any) => {
        this.roleList = res;
      })
  }
  addPhase() {
    this.chartData.push(
      {
        id: null,
        code: 'Proverka',
        name: '',
        status: '',
        description: '',
        startPlan: '',
        endPlan: '',
        rights: [
          {
            accessLevel: "ADMIN",
            roles: []
          },
          {
            accessLevel: "EDIT",
            roles: []
          },
          {
            accessLevel: "READ",
            roles: []
          },
          {
            accessLevel: "APPROVE",
            roles: []
          },
          {
            accessLevel: "CHANGE_STATUS",
            roles: []
          },
        ],
        startFact: null,
        endFact: null,
        isEdit: true
      }
    )
  }
  editPhase(event) {
    event.isEdit = true;
  }
  savePhase(event) {
    event.isEdit = false;
    this.data.info.lifeCycle.phases = this.chartData;
    console.log(this.chartData)
    this.changeDetectionRef.markForCheck();
  }
  deletePhase(event) {
    this.chartData.splice(this.chartData.indexOf(event), 1)
    this.data.info.lifeCycle.phases = this.chartData;
    this.changeDetectionRef.markForCheck();
  }

  selectPhaseRights(event, accessLevel, index) {
    this.chartData[index].rights.find(res => res.accessLevel == accessLevel).roles = event
    this.data.info.lifeCycle.phases = this.chartData;
    this.changeDetectionRef.markForCheck()
  }
  getRights(rights, accessLevel ) {
    return rights.find(res => res.accessLevel == accessLevel)?.roles
  }
  selectPhaseStatus(event, index) {
    this.chartData[index].status = event;
    this.data.info.lifeCycle.phases = this.chartData;
    this.changeDetectionRef.markForCheck()
  }
  activatePhase(documentId, phaseId) {
    this.chartData.forEach(phase => {
      phase.isRegular = false;
      phase.titleButton = 'Активировать';
    });
    const activatedPhase = this.chartData.find(phase => phase.id === phaseId);
    if (activatedPhase) {
      activatedPhase.isRegular = true;
      activatedPhase.titleButton = 'Активировано';
    }
    this.documentApi.changePhaseDocument(documentId, phaseId).subscribe((res) =>
      {
        console.log(res);
        this.data.info.currentPhaseId = phaseId;
        this.changeDetectionRef.markForCheck();
    });
  }
  onFileSelected(): void {
    this.fileInput.nativeElement.click();
  }
  onDeleteFile(index: number) {
    this.selectedFiles.splice(index, 1);
    // this.attachmentApi.deleteAttachmentById(index)
  }
  onFilesSelected(event: any) {
    if (event.target.files.length > 0) {
      const formData = new FormData();
      const loadingStates: LoadingState[] = [];
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        formData.append('content', file, file.name);
        loadingStates.push({ fileName: file.name, isLoading: true });
      }
      this.attachmentApi.setAttachments(this.data.info.type, this.data.info.id, formData).subscribe(
        async (response) => {
          console.log(response);
          await this.getAttachments(this.data.info.type, this.data.info.id);
          this.isLoading.forEach((state) => state.isLoading = false);
        },
        (error) => {
          console.log(error);
          this.isLoading.forEach((state) => state.isLoading = false);
        }
      );
    } else {
      console.log('Ничего не выбрано.');
    }
    setTimeout(() => {
      this.isLoading.forEach((state) => state.isLoading = false);
      this.changeDetectionRef.detectChanges();
    }, 2000);
  }
  getAttachments(parentType, parentId): Promise<void> {
    return new Promise((resolve, reject) => {
        this.attachmentApi.getAttachments(parentType, parentId)
        .subscribe(
            (res) => {
                this.attachmentsData = res['content'];
                this.changeDetectionRef.detectChanges();
                resolve();
            },
            (error) => {
                console.error('Ошибка при получении вложений:', error);
                reject(error);
            }
        );
    });
}
deleteAttachment(id) {
  this.attachmentApi.deleteAttachmentById(id)
    .subscribe((res) => {
        console.log(res);
        this.getAttachments(this.data.info.type, this.data.info.id)
        .then(() => {
          this.changeDetectionRef.detectChanges();
        });
      });
}
downloadAttachment(id, fileName) {
  this.attachmentApi.downloadAttachmentById(id).subscribe(
    (res: Blob) => {
      const url = window.URL.createObjectURL(res);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(url);
    },
    (error) => {
      console.error('Ошибка загрузки файла:', error);
    }
  );
}
openFileToView(file) {
    file.editDocument = false;
  this.dialogRef.close(file);
}
  recordVersionHistory() {
    this.documentApi.recordVersionHistory(this.data.info.id,'')
      .subscribe(() => {
        this.notificationService.message$.next('Новая версия документа успешно создана')
      })
  }
  routeToVersionHistory() {
    this.router.navigate(['project/'+this.id+'/work-material/version-history/'+this.data.info.id])
  }

  reMergeDocument(data){
    data.mergeDocument = true;
    this.dialogRef.close(data);
  }

}
