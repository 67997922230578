import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {MainPageComponent} from "./pages/main-page/main-page.component";
import {AddProjectComponent} from "./pages/main-settings/components/add-project/add-project.component";
import {AuthGuard} from "./utility/app.guard";
import { SearchPageComponent } from "./pages/search-page/search-page.component";
import {TaskTrackerTable} from "./pages/task-tracker/task-tracker-table-2.component";
import {MyTaskComponent} from "./pages/my-task/my-task.component";
import {NoticeComponent} from "./pages/notice/notice.component";

const routes: Routes = [
  {
    path: '',
    loadComponent: () => MainPageComponent,
    data: {
      isMenuItem: false
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'project/:id',
    loadChildren: () => import('./pages/main-project-layout/main-project-layout.module').then(m => m.MainProjectLayoutModule),
    data: {
      isMenuItem: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'add_project',
    component: AddProjectComponent,
    data: {
      isMenuItem: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'search',
    component: SearchPageComponent,
    data: {
      isMenuItem: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'tasks',
    component: MyTaskComponent,
    data: {
      isMenuItem: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'notice',
    component: NoticeComponent,
    data: {
      isMenuItem: true
    },
    canActivate: [AuthGuard]
  },

]
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
