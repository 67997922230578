import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class CurrentUserService {
 public currentUser: Subject<any> = new Subject<any>()
}
