import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input, OnChanges,
  OnInit, SimpleChanges,
} from '@angular/core';
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-progress-bar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ProgressBarComponent implements OnInit, OnChanges {
  @Input() chartData: any = [];
  @Input() height: string = '50px'
  @Input() isSize: boolean = true; // Для таблица статус false

  public daysDifference: number = 1;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes['chartData'].currentValue) {
      this.init();
    }
  }
  init() {
    this.chartData.map((res) => {
      const startDate = new Date(res.startPlan);
      const endDate = new Date(res.endPlan);
      const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());
      res.daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
      return res;
    })
  }


}
