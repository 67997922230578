import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {NGX_ECHARTS_CONFIG, NgxEchartsModule} from "ngx-echarts";
import {CommonModule} from "@angular/common";
import {EChartsOption} from "echarts";

@Component({
  selector: 'app-bar-chart',
  standalone: true,
  imports: [CommonModule, NgxEchartsModule],
  templateUrl: './bar-chart.component.html',
  styleUrl: './bar-chart.component.scss',
  providers: [
    {
      provide: NGX_ECHARTS_CONFIG,
      useFactory: () => ({echarts: () => import('echarts')})
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BarChartComponent implements OnInit,OnChanges{
  chartOption: EChartsOption = {}
  @Input() chartData: any = [];
  @Input() height: string = '50px'
  constructor( private _changeDetectorRef: ChangeDetectorRef) {
  }
  ngOnInit() {
    console.log(this.chartData)
    this.draw();
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log(changes, 'CHANGES')
    this.draw()
  }
  draw() {
    if(this.chartData.length == 0) {
      return;
    }
    let testMass: any = this.chartData.slice()
    console.log(testMass)
    testMass.map((res)=> {
      res.data.value = Math.ceil((Date.parse(res.data.endPlan) - Date.parse(res.data.startPlan)) / 86400000)
    })
    console.log(this.chartData)
    this.chartOption = {
      grid: {
        top: '0',
        left: 0,
        right: 0,
        bottom: '0',
        width: '100%'
      },
      xAxis: {
        show: false,
        type: 'value',
        max: () => {
          return testMass.map((res: any) => res.data.value).reduce((acc:any,current:any) => { return acc + current})
        }
      },
      yAxis: {
        show: false,
        type: 'category',
      },
      series: this.getSeriesData(testMass)
    }
  }
  getSeriesData(testMass) {
    let result: any = []
    for (let i = 0; i < this.chartData.length; i++) {
      let borderRadius;
      i == 0 ? borderRadius = [4,0,0,4] : i + 1 == this.chartData.length ? borderRadius = [0,4,4,0] : 0
      result.push({
        name: i,
        type: 'bar',
        stack: 'total',
        itemStyle: {
          color: this.chartData[i].color,
          borderWidth: 1,
          borderRadius: borderRadius
        },
        label: {
          show: true,
          color: '#FFFFFF',
          formatter: (res:any)  => {
            return res.name
          }
        },
        data: [testMass[i].data]
      })
    }
    console.log(result)
    return result;
  }
}
