<div class="notice_list_container">
  <div class="notice-item" *ngFor="let notice of noticeList | paginate: config" [ngClass]="{'unread':notice.status == 'UNREAD'}" >
    <div class="notice-item__main-info-container">
      <div class="notice-item__name">
        <span class="text-18 text-600 app-eerie-black-color">{{notice.name}} </span>
      </div>
      <div class="notice-item__route_icon active-notice">
        <mat-icon (click)="routeToEntity(notice)"
          class="search-link cursor-pointer"
          *ngIf="true"
          svgIcon="linkIcon"
        ></mat-icon>
      </div>
    </div>
    <div class="notice-item__description">
     <span class="text-14 text-400 app-eerie-black-color" style="font-weight: 300" >{{notice.description}} </span>
    </div>
  </div>
</div>
<div style="height: 48px; padding-top: 15px; padding-bottom: 19px" >
  <pagination-template #p="paginationApi"
                       [id]="config.id || ''"
                       [maxSize]="10"
                       (pageChange)="config.currentPage = $event">
    <div class="custom-pagination" >
      <div  style="margin-left: 30px" *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
        <a (click)="p.setCurrent(page.value); currentPage.emit(page.value)" *ngIf="p.getCurrent() !== page.value">
          <span class="pages">{{ page.label }}</span>
        </a>
        <div *ngIf="p.getCurrent() === page.value">
          <span> {{ page.label }}</span>
        </div>
      </div>
    </div>

  </pagination-template>
</div>
