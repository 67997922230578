import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})

export class ProjectApi {
 apiUrl = '/skrndapp'
 headers: HttpHeaders;
 token: string|null;
  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('view_auth_token');
    this.headers = new HttpHeaders().append('Authorization', `Basic ${this.token}`)
  }

  getProjectList() {
   return this.http.get(`${this.apiUrl}/projects`, {
     params: {
       page: 0,
       size: 50,
       sortBy: 'id',
       sortDirection: 'ASC'
     },
     headers: this.headers
   })
  }
  getProjectById(id: any) {
   return this.http.get(`${this.apiUrl}/project/${id}`, {
     headers: this.headers
   })
  }
  saveProject(data) {
   return this.http.post(`${this.apiUrl}/project`, data, {
     headers: this.headers
   })
  }
  deleteProject(id) {
   return this.http.delete(`${this.apiUrl}/project/${id}`, {
     headers: this.headers
   })
  }
}
