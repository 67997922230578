import {Component, forwardRef, EventEmitter, OnDestroy, OnInit, Input, Output, ElementRef, ViewChild, NgModule} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter, Subscription} from "rxjs";
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import {  DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

@Component({
    imports: [
        MatIconModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    standalone: true,
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrl: './input.component.scss',
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputComponent),
        multi: true,
      },
    ],
})

export class InputComponent implements OnInit, ControlValueAccessor {
  @Input() isPlaceholder: string = '';
  @Input() isName: string = 'input';
  @Input() label: string = 'Label';
  @Input() isDisabled: boolean = false;
  @Input() isError: boolean = false;
  @Input() isErrorText: string = '';
  @Input() isInfoIcon: boolean = false;
  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();

  @ViewChild('inputElement') inputElement!: ElementRef;

  onChange = (_: any) => {console.log(_)};
  onTouched = () => {};

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    // console.log(this.isIcon, 'this.isIcon')

  }

  ngOnInit() {
    this.matIconRegistry.addSvgIcon(
      'alert-circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/alert-circle.svg')
    );
  }

  writeValue(value: any): void {
    if (this.inputElement) {
    this.inputElement.nativeElement.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  inputValue(event: any) {
    this.valueChange.emit(event);
  }

}
