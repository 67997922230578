<div class="edit-project-container" *ngIf="!isEdit  && projectData">
  <div class="main-info-container" >
    <div class="main-info-container__tittle">
      <span class="text-18" style="font-weight: 600" > Основная информация*</span>
    </div>
    <div class="main-info-container__item_row">
      <div class="main-info-container__item_col">
        <app-input class="w-100" [isDisabled]="true" [label]="'Код'" [value]="projectData.code" ></app-input>
      </div>
      <div class="main-info-container__item_col">
        <app-input class="w-100" [isDisabled]="true" [label]="'Режим работы с документами'" [value]="documentHandlingModeArray.join(', ') || '-'" ></app-input>
      </div>
      <div class="main-info-container__item_col">
        <!-- <app-datepicker [isDisabled]="false" [label]="'Дата начала проекта'" [(value)]="getDatepiker"></app-datepicker> -->
        <app-input class="w-100" [isDisabled]="true" [label]="'Дата начала проекта'" [value]="projectData.startPlan | date: 'dd.MM.YYYY'"></app-input>
      </div>
      <div class="main-info-container__item_col">
        <app-input class="w-100" [isDisabled]="true" [label]="'Дата окончания проекта'" [value]="projectData.endPlan | date: 'dd.MM.YYYY'"></app-input>
      </div>
    </div>
    <div class="main-info-container__item_row">
      <div class="main-info-container__item_col">
        <app-textarea class="w-100" [isShowCounter]="false" [isDisabled]="true" [label]="'Название проекта'" [value]="projectData.name"></app-textarea>
      </div>
      <div class="main-info-container__item_col">
        <app-textarea class="w-100" [isDisabled]="true" [isShowCounter]="false" [label]="'Описание'" [value]="projectData.description"></app-textarea>
      </div>
    </div>
    <div class="main-info-container__item_row">
      <div class="main-info-container__item_col">
        <app-input class="w-100" [isDisabled]="true" [label]="'Тип'" [value]="projectData.type"></app-input>
      </div>
    </div>
  </div>
  <div class="main-info-container app-mt-10px">
    <div class="main-info-container__tittle">
      <span class="text-18" style="font-weight: 600" > Команда проекта</span>
    </div>
    <div class="main-info-container__item_row">
      <div class="main-info-container__item_col">
        <app-input class="w-100" [isDisabled]="true" [label]="'Куратор'" [value]="membersObject.curator"  ></app-input>
      </div>
      <div class="main-info-container__item_col">
        <app-input class="w-100" [isDisabled]="true" [label]="'Ответственный'" [value]="membersObject.responsible" ></app-input>
      </div>
    </div>
    <div class="main-info-container__item_row app-mt-10px app-mb-20px" >
      <div class="main-info-container__item_col">
        <app-input class="w-100" [isDisabled]="true" [label]="'Участник рабочей группы'" [value]="membersObject.member_work_group"></app-input>
      </div>
      <div class="main-info-container__item_col">
        <app-input class="w-100" [isDisabled]="true" [label]="'Согласующий'"  [value]="membersObject.approver"></app-input>
      </div>
    </div>
  </div>
  <div class="main-info-container app-mt-10px">
    <div class="main-info-container__tittle">
      <span class="text-18" style="font-weight: 600" >Фазы жизненного цикла документов (ЖЦ назначаемый по умолчанию) </span>
    </div>
    <div class="main-info-container__item_row">
        <div class="main-info-container__item_col">
<!--          <app-input class="w-100" [isDisabled]="true" [label]="'Дата начала'" [value]="projectData.lifeCycle.startPlan | date: 'dd.MM.YYYY'" ></app-input>-->
        </div>
        <div class="main-info-container__item_col">
<!--          <app-input class="w-100" [isDisabled]="true" [label]="'Дата окончания'" [value]="projectData.lifeCycle.endPlan | date: 'dd.MM.YYYY'"></app-input>-->
        </div>
        <div class="main-info-container__item_col">
        </div>
        <div class="main-info-container__item_col">
        </div>
      </div>
    <div class="main-info-container__item_row">
        <div class="main-info-container__item_col">
          <app-progress-bar class="w-100" [isSize]="true" [chartData]="projectData.lifeCycle.phases"></app-progress-bar>
        </div>
  </div>
  </div>
  <div class="phases-container">
    <div class="phases-container__item" *ngFor="let phase of projectData.lifeCycle.phases">
      <mat-accordion [togglePosition]="'before'">
        <mat-expansion-panel>
          <mat-expansion-panel-header class="app-mt-4px">
            <mat-panel-title class="text-18 text-600"> Фаза: {{phase.name}}</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="phases-container__content">
            <div class="phases-container__row">
              <div class="phases-container__col">
                <app-input class="w-100" [isDisabled]="true" [label]="'Статус'" [value]="phase.status" ></app-input>
              </div>
              <div class="phases-container__col">
                <app-input class="w-100" [isDisabled]="true" [label]="'Дата начала'" [value]="phase.startPlan| date: 'dd.MM.YYYY'" ></app-input>
              </div>
              <div class="phases-container__col">
                <app-input class="w-100" [isDisabled]="true" [label]="'Дата окончания'" [value]="phase.endPlan| date: 'dd.MM.YYYY'" ></app-input>
              </div>
            </div>
            <div class="phases-container__row">
              <div class="phases-container__col">
                <app-textarea class="w-100" [isShowCounter]="false" [isDisabled]="true" [label]="'Название'" [value]="phase.name"></app-textarea>
              </div>
              <div class="phases-container__col">
                <app-textarea class="w-100" [isDisabled]="true" [isShowCounter]="false" [label]="'Описание'" [value]="phase.description"></app-textarea>
              </div>
            </div>
            <div class="phases-container__row">
              <div class="phases-container__col">
                <app-input class="w-100" [isDisabled]="true" [label]="'Администрирование документа'" [value]="getRights(phase.rights, 'ADMIN')" ></app-input>
              </div>
              <div class="phases-container__col">
                <app-input class="w-100" [isDisabled]="true" [label]="'Исправление документа'" [value]="getRights(phase.rights, 'EDIT')" ></app-input>
              </div>
            </div>
            <div class="phases-container__row">
              <div class="phases-container__col">
                <app-input class="w-100" [isDisabled]="true" [label]="'Чтение документа'" [value]="getRights(phase.rights, 'READ')" ></app-input>
              </div>
              <div class="phases-container__col">
                <app-input class="w-100" [isDisabled]="true" [label]="'Согласование документа'" [value]="getRights(phase.rights, 'APPROVE')" ></app-input>
              </div>
            </div>
            <div class="phases-container__row">
              <div class="phases-container__col">
                <app-input class="w-100" [isDisabled]="true" [label]="'Смена статуса документа'" [value]="getRights(phase.rights, 'CHANGE_STATUS')" ></app-input>
              </div>
              <div class="phases-container__col">
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div class="files-container">
    <div class="files-container-download-row">
      <span *ngIf="isEdit" class="text-16" style="font-weight: 600">Загрузить файлы</span>
      <span *ngIf="!isEdit" class="text-16" style="font-weight: 600; padding-top: 10px">Загруженные файлы</span>
      <app-button *ngIf="isEdit"
        (click)="onFileSelectedMainSettings()"
        [titleButton]="'Загрузить'"
        [isRegular]="true"
      ></app-button>
    </div>
    <div class="files-container-files_block">
      <input #fileInput type="file" style="display: none;"  multiple (change)="onFilesSelectedMainSettings($event)">
      <div *ngIf="attachmentsData && attachmentsData.length > 0">
        <div class="files-container-files_block-scroll-item" *ngFor="let attachment of attachmentsData; let i = index">
          <div class="files-container-files_block-scroll-item-name">
            <mat-icon *ngIf="true" svgIcon="fileIcon"></mat-icon>
            <span class="fileName text-14 text-400">{{attachment.name}}</span>
          </div>
          <div class="loading-indicator" *ngIf="isLoading[i]">
            <div>
              <div class="fill" [@progressAnimation]></div>
            </div>
          </div>
          <!-- <span *ngIf="!isLoading[i]" class="fileName text-14 text-400">1.2 Мб</span> -->
          <span *ngIf="!isLoading[i]" class="fileName text-14 text-400">{{attachment.createdDate | date: 'dd.MM.yyyy'}}</span>
          <span *ngIf="!isLoading[i]" class="fileName text-14 text-400">{{attachment.creator.lastName + ' ' + attachment.creator.firstName }}</span>
          <div class="files-container-files_block-scroll-item-files_row">
            <mat-icon
              class="size"
              *ngIf="!isLoading[i]"
              svgIcon="openFileIcon"
              (click)="openFileToView(attachment)"
            ></mat-icon>
            <mat-icon
              class="size"
              *ngIf="!isLoading[i]"
              svgIcon="downloadFileIcon"
              (click)="downloadAttachmentMainSettings(attachment.id, attachment.name)"
            ></mat-icon>
            <app-button  *ngIf="(!isLoading[i] && isEdit)" (click)="deleteAttachmentMainSettings(attachment.id)" [isRegular]="true" [isIcon]="'trash'"></app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- ============================= элементы в редиме редаактирвоания ============================================================================  -->
<div class="edit-project-container" *ngIf="isEdit && projectData">
  <div class="main-info-container" >
    <div class="main-info-container__tittle">
      <span class="text-18" style="font-weight: 600" > Основная информация*</span>
    </div>
    <div class="main-info-container__item_row">
      <div class="main-info-container__item_col">
        <app-input class="w-100"  [label]="'Код'" [(value)]="projectData.code" isPlaceholder="Ввести код" ></app-input>
      </div>
      <div class="main-info-container__item_col">
        <app-dropdown class="w-100" [label]="'Режим работы с документами'" [placeholder]="'Выбрать режим'"
                      (valueChange)="selectDocumentHandling($event)" [defaultSelectedNames]="documentHandlingModeArray"
                      [listDropdown]="documentHandlingModeList" [isMultiple]="true" ></app-dropdown>
      </div>
      <div class="main-info-container__item_col">
        <app-datepicker class="w-100" [label]="'Дата начала проекта'" [(value)]="projectData.startPlan" ></app-datepicker>
      </div>
      <div class="main-info-container__item_col">
        <app-datepicker class="w-100" [label]="'Дата окончания проекта'" [(value)]="projectData.endPlan"  ></app-datepicker>
      </div>
    </div>
    <div class="main-info-container__item_row">
      <div class="main-info-container__item_col">
        <app-textarea class="w-100" [maxLength]="350" [label]="'Название проекта'" [(value)]="projectData.name" isPlaceholder="Введите название проекта"></app-textarea>
      </div>
      <div class="main-info-container__item_col">
        <app-textarea class="w-100" [maxLength]="350"  [label]="'Описание'" [(value)]="projectData.description" isPlaceholder="Введите описание проекта"></app-textarea>
      </div>
    </div>
    <div class="main-info-container__item_row">
      <div class="main-info-container__item_col">
        <app-input class="w-100"  isPlaceholder="Введите тип" [label]="'Тип'" [(value)]="projectData.type"></app-input>
      </div>
    </div>
  </div>
  <div class="main-info-container app-mt-10px">
    <div class="main-info-container__tittle">
      <span class="text-18" style="font-weight: 600" > Команда проекта</span>
    </div>
    <div class="main-info-container__item_row">
      <div class="main-info-container__item_col">
        <app-dropdown class="w-100"  [label]="'Куратор'"  [listDropdown]="userList"   [isMultiple]="true"
                      [placeholder]="'Выберите из списка'" [defaultSelectedNames]="membersObject.curator"
                      (valueChange)="selectMember($event, 'CURATOR')"></app-dropdown>
      </div>
      <div class="main-info-container__item_col">
        <app-dropdown class="w-100"  [label]="'Ответственный'" [listDropdown]="userList"  [isMultiple]="true"
                      [placeholder]="'Выберите из списка'" [defaultSelectedNames]="membersObject.responsible"
                      (valueChange)="selectMember($event, 'RESPONSIBLE')"  ></app-dropdown>
      </div>
    </div>
    <div class="main-info-container__item_row app-mt-10px app-mb-20px" >
      <div class="main-info-container__item_col">
        <app-dropdown class="w-100"  [label]="'Участник рабочей группы'" [isMultiple]="true"
                      [listDropdown]="userList" [placeholder]="'Выберите из списка'" [defaultSelectedNames]="membersObject.member_work_group"
                      (valueChange)="selectMember($event, 'MEMBER_WORK_GROUP')" ></app-dropdown>
      </div>
      <div class="main-info-container__item_col">
        <app-dropdown class="w-100"  [label]="'Согласующий'" [listDropdown]="userList"  [isMultiple]="true"
                      [placeholder]="'Выберите из списка'" [defaultSelectedNames]="membersObject.approver"
                      (valueChange)="selectMember($event, 'APPROVER')"  ></app-dropdown>
      </div>
    </div>
  </div>
  <div class="main-info-container app-mt-10px">
    <div class="main-info-container__tittle">
      <span class="text-18" style="font-weight: 600" >Фазы жизненного цикла документов (ЖЦ назначаемый по умолчанию) </span>
    </div>
    <div class="main-info-container__item_row">
      <div class="main-info-container__item_col">
<!--        <app-datepicker class="w-100" [label]="'Дата начала'"  [(value)]="projectData.lifeCycle.startPlan"></app-datepicker>-->
      </div>
      <div class="main-info-container__item_col">
<!--        <app-datepicker class="w-100" [label]="'Дата окончания'" [(value)]="projectData.lifeCycle.endPlan" ></app-datepicker>-->
      </div>
      <div class="main-info-container__item_col">
      </div>
      <div class="main-info-container__item_col justify-content-end align-items-end">
        <app-button titleButton="Добавить фазу" (click)="addPhase()"></app-button>
      </div>
    </div>
    <div class="main-info-container__item_row">
      <div class="main-info-container__item_col">
        <app-progress-bar class="w-100" [isSize]="true" [chartData]="chartData"></app-progress-bar>
      </div>
    </div>
  </div>
  <div class="phases-container">
    <div class="phases-container__item w-100" *ngFor="let phase of projectData.lifeCycle.phases; let i = index;">
      <mat-accordion togglePosition="before" class="w-100">
        <mat-expansion-panel [expanded]="phase.isEdit" >
          <mat-expansion-panel-header class="app-mt-4px" >
            <mat-panel-title class="text-18 text-600"> Фаза: {{phase.name}}</mat-panel-title>
            <mat-panel-description class="justify-content-end">
             <app-button class="app-mr-15px" *ngIf="!phase.isEdit" (click)="editPhase(phase); $event.stopPropagation();" [titleButton]="'Редактировать'" [isRegular]="true"></app-button>
             <app-button class="app-mr-15px" *ngIf="phase.isEdit" (click)="savePhase(phase, i);$event.stopPropagation()" [titleButton]="'Сохранить'" [isRegular]="true"></app-button>
             <app-button [isIcon]="'trash'" [isRegular]="true" (click)="deletePhase(phase)"></app-button>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="phases-container__content">
            <div class="phases-container__row">
              <div class="phases-container__col">
                <app-dropdown class="w-100" [isDisabled]="!phase.isEdit" [label]="'Статус'" [placeholder]="'Выберите из списка'"  [listDropdown]="phaseStatusList" (valueChange)="selectPhaseStatus($event, i)"  ></app-dropdown>
              </div>
              <div class="phases-container__col">
                <app-datepicker class="w-100" [isDisabled]="!phase.isEdit" [label]="'Дата начала'"  [(value)]="phase.startPlan"  ></app-datepicker>
              </div>
              <div class="phases-container__col">
                <app-datepicker class="w-100" [isDisabled]="!phase.isEdit" [label]="'Дата окончания'" [(value)]="phase.endPlan"  ></app-datepicker>
              </div>
            </div>
            <div class="phases-container__row app-mt-10px">
              <div class="phases-container__col">
                <app-textarea class="w-100" [isDisabled]="!phase.isEdit" [maxLength]="350" [label]="'Название'" [(value)]="phase.name"></app-textarea>
              </div>
              <div class="phases-container__col">
                <app-textarea class="w-100" [isDisabled]="!phase.isEdit" [maxLength]="350" [label]="'Описание'" [(value)]="phase.description"></app-textarea>
              </div>
            </div>
            <div class="phases-container__row">
              <div class="phases-container__col">
                <app-dropdown class="w-100" [isDisabled]="!phase.isEdit"  [label]="'Администрирование документа'" [defaultSelectedIds]="getRights(phase.rights, 'ADMIN')"
                              [placeholder]="'Выберите из списка'"  [listDropdown]="roleList" [isMultiple]="true"
                              (valueChange)="selectPhaseRights($event, 'ADMIN',i)" ></app-dropdown>
              </div>
              <div class="phases-container__col">
                <app-dropdown class="w-100" [isDisabled]="!phase.isEdit"  [label]="'Исправление документа'" [defaultSelectedIds]="getRights(phase.rights, 'EDIT')"
                              [placeholder]="'Выберите из списка'"  [listDropdown]="roleList" [isMultiple]="true"
                              (valueChange)="selectPhaseRights($event, 'EDIT',i)" ></app-dropdown>
              </div>
            </div>
            <div class="phases-container__row app-mt-10px">
              <div class="phases-container__col">
                <app-dropdown class="w-100" [isDisabled]="!phase.isEdit"  [label]="'Чтение документа'" [defaultSelectedIds]="getRights(phase.rights, 'READ')"
                              [placeholder]="'Выберите из списка'"  [listDropdown]="roleList" [isMultiple]="true"
                              (valueChange)="selectPhaseRights($event, 'READ',i)" ></app-dropdown>
              </div>
              <div class="phases-container__col">
                <app-dropdown class="w-100" [isDisabled]="!phase.isEdit"  [label]="'Согласование документа'" [defaultSelectedIds]="getRights(phase.rights, 'APPROVE')"
                              [placeholder]="'Выберите из списка'"  [listDropdown]="roleList" [isMultiple]="true"
                              (valueChange)="selectPhaseRights($event, 'APPROVE',i)" ></app-dropdown>
              </div>
            </div>
            <div class="phases-container__row app-mt-10px app-mb-220px">
              <div class="phases-container__col">
                <app-dropdown class="w-100" [isDisabled]="!phase.isEdit"  [label]="'Смена статуса документа'" [defaultSelectedIds]="getRights(phase.rights, 'CHANGE_STATUS')"
                              [placeholder]="'Выберите из списка'"  [listDropdown]="roleList" [isMultiple]="true"
                              (valueChange)="selectPhaseRights($event, 'CHANGE_STATUS',i)" ></app-dropdown>
              </div>
              <div class="phases-container__col">
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div *ngIf="!isAdd" class="files-container">
    <div class="files-container-download-row">
      <span class="text-16" style="font-weight: 600">Загрузить файлы</span>
      <app-button
        (click)="onFileSelectedMainSettings()"
        [titleButton]="'Загрузить'"
        [isRegular]="true"
      ></app-button>
    </div>
    <div class="files-container-files_block">
      <input #fileInput type="file" style="display: none;"  multiple (change)="onFilesSelectedMainSettings($event)">
      <div *ngIf="attachmentsData && attachmentsData.length > 0">
        <div class="files-container-files_block-scroll-item" *ngFor="let attachment of attachmentsData; let i = index">
          <div class="files-container-files_block-scroll-item-name">
            <mat-icon *ngIf="true" svgIcon="fileIcon"></mat-icon>
            <span class="fileName text-14 text-400">{{attachment.name}}</span>
          </div>
          <div class="loading-indicator" *ngIf="isLoading[i]">
            <div>
              <div class="fill" [@progressAnimation]></div>
            </div>
          </div>
          <!-- <span *ngIf="!isLoading[i]" class="fileName text-14 text-400">1.2 Мб</span> -->
          <span *ngIf="!isLoading[i]" class="fileName text-14 text-400">{{attachment.createdDate | date: 'dd.MM.yyyy'}}</span>
          <span *ngIf="!isLoading[i]" class="fileName text-14 text-400">{{attachment.creator.lastName + ' ' + attachment.creator.firstName }}</span>
          <div class="files-container-files_block-scroll-item-files_row">
            <mat-icon
              class="size"
              *ngIf="!isLoading[i]"
              svgIcon="openFileIcon"
              (click)="openFileToView(attachment)"
            ></mat-icon>
            <mat-icon
              class="size"
              *ngIf="!isLoading[i]"
              svgIcon="downloadFileIcon"
              (click)="downloadAttachmentMainSettings(attachment.id, attachment.name)"
            ></mat-icon>
            <app-button *ngIf="!isLoading[i]" (click)="deleteAttachmentMainSettings(attachment.id)" [isRegular]="true" [isIcon]="'trash'"></app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="button_container">
  <app-button *ngIf="isEdit" [titleButton]="isAdd ? 'Создать' : 'Сохранить' " class="" (click)="saveProject()"></app-button>
</div>

