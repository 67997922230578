<div class="navigation-container">
</div>
<div class="main-settings-container">
  <div class="d-flex justify-content-between">
    <div class="title text-22" >
      <span class="text-700">Создание проекта</span>
    </div>
  </div>
  <app-main-settings-characteristics  [data]="projectMock" [isEdit]="true" [isAdd]="true"></app-main-settings-characteristics>
</div>
