import {animate, query, stagger, style, transition, trigger} from "@angular/animations";

export const transitionOpacity = trigger('transitionOpacity', [
  transition('* <=> *', [
    query(
      ':enter',
      [style({opacity: 0}), stagger('60ms', animate('600ms ease-out', style({opacity: 1})))],
      {optional: true},
    ),
    query(':leave', animate('200ms', style({opacity: 0})), {optional: true}),
  ]),
]);

export const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(
      ':enter',
      [
        style({opacity: 0, transform: 'translateX(50px)'}),
        stagger('60ms', animate('150ms', style({opacity: 1, transform: 'translateX(0)'}))),
      ],
      {optional: true},
    ),
    query(':leave', animate('150ms', style({opacity: 0, transform: 'translateX(50px)'})), {
      optional: true,
    }),
  ]),
]);
