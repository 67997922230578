import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDateMask]'
})
export class DateMaskDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, '');
    if (value.length > 8) {
      value = value.substring(0, 8);
    }

    let formattedValue = '';
    if (value.length > 4) {
      formattedValue = `${value.substring(0, 2)}.${value.substring(2, 4)}.${value.substring(4)}`;
    } else if (value.length > 2) {
      formattedValue = `${value.substring(0, 2)}.${value.substring(2)}`;
    } else {
      formattedValue = value;
    }

    input.value = formattedValue;
    const eventInput = new Event('input', { bubbles: true });
    input.dispatchEvent(eventInput);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!event.key.match(/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|Tab/)) {
      event.preventDefault();
    }
  }
}
