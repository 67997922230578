import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class MainProjectLayoutService {
  id = new BehaviorSubject(null);
}
