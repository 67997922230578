<div class="add-task-container">
  <div class="header">
    <div class="title">
      <span class="text-18 text-600">Добавление задачи</span>
    </div>
    <mat-icon
      class="item-close"
      *ngIf="true"
      svgIcon="closeIcon"
      (click)="closeDialog()"
    ></mat-icon>
  </div>
  <div class="add-task-container-inside-block">
    <!-- <app-dropdown
      [type]="'text'"
      [label]="'Выбрать родительский элемент'"
      [placeholder]="isPlaceholder"
      [listDropdown]="isDropListAdd"
      (valueChange)="onSelectedIdChange($event)"
    ></app-dropdown> -->
    <app-input
      [label]="'Наименование'"
      [isPlaceholder]="'Введите название задачи'"
      [(value)]="isNameFolder"
    ></app-input>
    <app-textarea
      [label]="'Описание'"
      [isPlaceholder]="'Введите описание задачи'"
      [(value)]="isDescriptionFolder"
    ></app-textarea>
    <div class="add-task-container-inside-block-date">
    <app-datepicker [isDisabled]="false" [label]="'Дата начала'" [(value)]="startPlan"></app-datepicker>
    <app-datepicker [isDisabled]="false" [label]="'Дата окончания'" [(value)]="endPlan"></app-datepicker>
  </div>
  <app-input [isDisabled]="true" [label]="'Автор'" [value]="userInfo?.firstName + ' ' + userInfo?.lastName"></app-input>
    <div class="add-task-container-inside-block-item">
      <app-dropdown [label]="'Исполнитель'"  [listDropdown]="userList"
      [placeholder]="'Выберите из списка'" (valueChange)="onUsersSelected($event)"></app-dropdown>
      <app-dropdown [label]="'Статус'"  [listDropdown]="statusList"
      [placeholder]="'Выберите из списка'" (valueChange)="onSelectedIdChange($event)"></app-dropdown>
      <app-dropdown [label]="'Приоритет'"  [listDropdown]="priorityList"
      [placeholder]="'Выберите из списка'" (valueChange)="onPriorityChange($event)"></app-dropdown>
   </div>
  </div>
  <div class="add-task-container-footer">
    <app-button
      [titleButton]="'Отменить'"
      [isSolid]="false"
      [isRegular]="true"
      (click)="closeDialog()"
      >Отмена</app-button
    >
    <app-button
      (click)="saveTask()"
      [titleButton]="'Сохранить'"
      [isSolid]="true"
      [isRegular]="false"
    ></app-button>
  </div>
</div>
