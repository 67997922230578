import {Component, forwardRef, EventEmitter, OnDestroy, OnInit, Input, Output, ElementRef, ViewChild, NgModule} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter, Subscription} from "rxjs";
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    imports: [
        MatIconModule,
        CommonModule,
        FormsModule,
    ],
    standalone: true,
    selector: 'app-textarea',
    templateUrl: './textarea.component.html',
    styleUrl: './textarea.component.scss',
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TextareaComponent),
        multi: true,
      },
    ],
})

export class TextareaComponent implements OnInit {
    @Input() label: string = 'Label';
    @Input() isDisabled: boolean = false;
    @Input() isSearch: boolean = false;
    @Input() isShowCounter: boolean = true;
    @Input() isPlaceholder: string = '';
    @Input() value: any = '';
    @Input() maxLength: number = 100;
    @Output() valueChange = new EventEmitter<any>();

    @ViewChild('textareaElement') textareaElement!: ElementRef;

    public isError: boolean = false;

      onChange = (_: any) => {};
      onTouched = () => {};

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {

  }

  ngOnInit() {
    this.matIconRegistry.addSvgIcon(
      'search',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/search.svg')
  );
  }

  dataTextarea(event:any) {
    this.valueChange.emit(event);
    this.isError = this.value.length > this.maxLength;
  }
  writeValue(value: any): void {
    if (this.textareaElement) {
    this.textareaElement.nativeElement.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.textareaElement = fn;
  }

  registerOnTouched(fn: any): void {
    this.textareaElement = fn;
  }

}
