<div *ngIf="!isLoaded" class="spinner-container-task-tracker">
  <mat-spinner [diameter]="120" class="mat-spinner-color" ></mat-spinner>
</div>
<table  mat-table cdkDropList *ngIf="isLoaded" (cdkDropListDropped)="onDrop($event)"
       [dataSource]="dataSource"
       class="mat-elevation-z8">
  <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column.field" >
    <!-- Head table -->
    <th mat-header-cell *matHeaderCellDef>
      <ng-container *ngIf="column.field == 'name'">
        {{ column.header }}
      </ng-container>
      <ng-container *ngIf="column.field == 'id'">
        <div class="headers">
          {{ column.header }}
        </div>
      </ng-container>
      <ng-container *ngIf="column.field !== 'name' && column.field !== 'id'">  {{ column.header }}</ng-container>
      <span class="resize-handle" (mousedown)="onResizeColumn($event, i)"></span>
    </th>
    <ng-container >
      <div  class="table-container__item" *ngFor="let element of dataSource.data; let elIndex = index" >
        <td mat-cell *matCellDef="let element"  >
          <div class="table-container__item-line"  (click)="changeExpend(element);" cdkDrag>
            <ng-container *ngIf="column.field !== 'phase' && column.field !== 'name' && column.field !== 'startPlan' && column.field !== 'endPlan' && column.field !== 'id' && column.field !== 'status' && column.field !== 'priority' && column.field !== 'parentDoc'"> {{element[column.field]}} </ng-container>
            <ng-container *ngIf="column.field == 'startPlan'"> {{element[column.field] | date:'dd.MM.yyyy'}} </ng-container>
            <ng-container *ngIf="column.field == 'endPlan'"> {{element[column.field] | date:'dd.MM.yyyy'}} </ng-container>
            <ng-container *ngIf="column.field == 'priority'">
              <div class="priority-icon-container">
                <mat-icon aria-hidden="false" aria-label="Example home icon" svgIcon="lowIcon" *ngIf="element['priority'] == 'LOW'" ></mat-icon>
                <mat-icon aria-hidden="false" aria-label="Example home icon" svgIcon="mediumIcon" *ngIf="element['priority'] == 'MEDIUM'" ></mat-icon>
                <mat-icon aria-hidden="false" aria-label="Example home icon" svgIcon="highIcon" *ngIf="element['priority'] == 'HIGH'" ></mat-icon>
              </div>
            </ng-container>

            <ng-container *ngIf="column.field == 'id'">
              <div [ngClass]="{'red-baboon' : (((compareTime(element['startPlan'],element['endPlan'])) == 1) && (element['status'] != 'Отклонено') && (element['status'] != 'Выполнено')) , 'gray-baboon' : element['status'] == 'Выполнено' || element['status'] == 'Отклонено', 'green-baboon' : ((compareTime(element['startPlan'],element['endPlan'])) == 3),}">  </div>
              <div> {{element[column.field]}} </div>
            </ng-container>

            <ng-container *ngIf="column.field == 'status'">
              <div [ngClass]="{'status-in-work' : element['status'] == 'В работе', 'status-complete' : element['status'] == 'Выполнено', 'status-search' : element['status'] == 'Запланировано', 'status-decline' : element['status'] == 'Отклонено'}">
                {{element[column.field]}}
              </div>
            </ng-container>

            <ng-container *ngIf="column.field == 'parentDoc'">
              <div class="related-entity" (click)="routeToDoc(element['parentDoc'].id)">
                {{element['parentDoc']?.name}}
              </div>
            </ng-container>

            <div class="table-container__item-line-name" *ngIf="column.field == 'name'">
              <!-- Icon arrow down -->
              <mat-icon class="arrow-down show-icon" aria-hidden="false" aria-label="Example home icon" svgIcon="arrow-down" *ngIf="element.children?.length > 0 && element.expand"></mat-icon>
              <mat-icon class="arrow-down show-icon" aria-hidden="false" aria-label="Example home icon" svgIcon="arrow-right-g" *ngIf="element.children?.length > 0 && !element.expand"></mat-icon>
              <ng-container *ngIf="element.type == 'SPACE'">
                <!-- Icon folder -->
                <mat-icon class="folder show-icon" aria-hidden="false" aria-label="Example home icon" svgIcon="folder"></mat-icon>
              </ng-container>
              <!-- <ng-container *ngIf="element.type == 'DOCUMENT'"> -->
                <!-- Icon file -->
                <!-- <mat-icon class="file action-icon" aria-hidden="false" aria-label="Example home icon" svgIcon="file" (click)="documentAction(element);$event.stopPropagation()"></mat-icon>
              </ng-container> -->

              <!-- Line field -->
              <div class="field" (click)="documentAction(element);$event.stopPropagation()">{{element[column.field]}}</div>
              <!-- Icon edit -->
              <mat-icon class="edit action-icon" aria-hidden="false" aria-label="Example home icon" (click)="editAction(element, element.id); $event.stopPropagation()" svgIcon="edit"></mat-icon>
              <!-- Icon plus -->
              <mat-icon class="plus action-icon" *ngIf="projectId"  aria-hidden="false" aria-label="Example home icon" (click)="addTask(element); $event.stopPropagation()" svgIcon="plus"></mat-icon>
            </div>



            <ng-container *ngIf="column.field == 'phase'" style="padding-right: 31px">
              <ng-container *ngIf="element[column.field]?.length > 0">
                <div>
                  <!-- <app-bar-chart [chartData]="element[column.field]" [height]="'25px'"></app-bar-chart> -->
                   <app-progress-bar [chartData]="element[column.field]"></app-progress-bar>
                </div>
              </ng-container>
              <ng-container *ngIf="element[column.field]?.length == 0"> - </ng-container>
            </ng-container>
          </div>

          <div class="table-container__item-date" *ngIf="column.field == 'date'">
            <ng-container *ngIf="element.creationDate">
              <span>{{ element.creationDate | date:'dd.MM.yyyy' }}</span>
            </ng-container>
            <!-- <ng-container *ngIf="!element.creationDate"> - </ng-container> -->
          </div>
          <!-- Children line -->
          <div class="child-element" *ngIf="element.children && element.children.length > 0 && element.expand">
            <ng-container *ngTemplateOutlet="tree; context:{node: element.children, columns: column, parent: element}"></ng-container>
          </div>
        </td>
      </div>
    </ng-container>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns;"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
  </tr>
</table>

<ng-template #tree let-node="node" let-col="columns" let-parent="parent" >
  <div cdkDropList (cdkDropListDropped)="onDropChild($event, node)">
      <div [ngClass]="{'child-list-name': col.field == 'name'}" *ngFor="let child of node">
        <div class="underline"></div>
        <div class="table-container__item-subline"  (click)="changeExpend(child)" cdkDrag>
          <ng-container *ngIf="col.field !== 'author'">
            <div class="table-container__item-subline-number" *ngIf="col.field !== 'phase' && col.field !== 'name' && col.field !== 'startPlan' && col.field !== 'endPlan' && col.field !== 'id' && col.field !== 'status' && col.field !== 'priority'"> {{child[col.field]}} </div>
          </ng-container>
          <div class="table-container__item-subline-name" *ngIf="col.field == 'name'">
            <!-- Icon arrow down -->
            <mat-icon class="arrow-down show-icon" aria-hidden="false" aria-label="Example home icon" svgIcon="arrow-down" *ngIf="child.children.length > 0 && child.expand"></mat-icon>
            <mat-icon class="arrow-down show-icon" aria-hidden="false" aria-label="Example home icon" svgIcon="arrow-right-g" *ngIf="child.children.length > 0 && !child.expand"></mat-icon>
            <ng-container *ngIf="child.type == 'SPACE'">
              <!-- Icon folder -->
              <mat-icon class="folder show-icon" aria-hidden="false" aria-label="Example home icon" svgIcon="folder"></mat-icon>
            </ng-container>



            <!-- <ng-container *ngIf="child.type == 'DOCUMENT'"> -->
              <!-- Icon file -->
              <!-- <mat-icon class="file action-icon" aria-hidden="false" aria-label="Example home icon" svgIcon="file" (click)="documentAction(child);$event.stopPropagation() "></mat-icon>
            </ng-container> -->

            <!-- Line child field  -->
            <div class="child-field " (click)="documentAction(child);$event.stopPropagation()">{{child[col.field]}}</div>
            <!-- Icon edit -->
            <mat-icon class="edit action-icon" aria-hidden="false" aria-label="Example home icon" svgIcon="edit" (click)="editAction(child, parent?.id); $event.stopPropagation()" ></mat-icon>
            <!-- Icon plus -->
            <mat-icon class="plus action-icon" aria-hidden="false" aria-label="Example home icon" svgIcon="plus" (click)="addTask(child); $event.stopPropagation()"></mat-icon>

          </div>

          <ng-container *ngIf="col.field == 'priority'">
            <div class="priority-icon-container">
              <mat-icon aria-hidden="false" aria-label="Example home icon" svgIcon="lowIcon" *ngIf="child['priority'] == 'low'" ></mat-icon>
              <mat-icon aria-hidden="false" aria-label="Example home icon" svgIcon="mediumIcon" *ngIf="child['priority'] == 'medium'" ></mat-icon>
              <mat-icon aria-hidden="false" aria-label="Example home icon" svgIcon="highIcon" *ngIf="child['priority'] == 'high'" ></mat-icon>
            </div>
          </ng-container>

            <ng-container *ngIf="col.field == 'id'">
                <div [ngClass]="{'red-baboon' : (((compareTime(child['startPlan'],child['endPlan'])) == 1) && (child['status'] !== 'Выполнено') && (child['status'] !== 'Отклонено')) , 'gray-baboon' : child['status'] == 'Выполнено' || child['status'] == 'Отклонено', 'green-baboon' : ((compareTime(child['startPlan'],child['endPlan'])) == 3),}">  </div>
                <div> {{child[col.field]}} </div>
            </ng-container>

            <ng-container *ngIf="col.field == 'status'">
              <div [ngClass]="{'status-in-work' : child['status'] == 'В работе', 'status-complete' : child['status'] == 'Выполнено', 'status-search' : child['status'] == 'Запланировано', 'status-decline' : child['status'] == 'Отклонено'}">
              {{child[col.field]}}
              </div>
            </ng-container>


          <ng-container *ngIf="col.field == 'startPlan'"> {{child[col.field] | date:'dd.MM.yyyy'}} </ng-container>
          <ng-container *ngIf="col.field == 'endPlan'"> {{child[col.field] | date:'dd.MM.yyyy'}} </ng-container>
          <!-- <div class="table-container__item-author" *ngIf="col.field == 'author'">
            <ng-container *ngIf="child.creator">
              <span>{{ child.creator.firstName }}</span>
              <span>{{ child.creator.lastName }}</span>
            </ng-container>
            <ng-container *ngIf="!child.creator"> - </ng-container>
          </div> -->


          <div class="table-container__item-date" *ngIf="col.field == 'date'">
            <ng-container *ngIf="child.creationDate">
              <span>{{ child.creationDate | date:'dd.MM.yyyy' }}</span>
            </ng-container>
            <!-- <ng-container *ngIf="!child.creationDate"> - </ng-container> -->
          </div>


          <div style="width: 100%;" *ngIf="col.field == 'phase'">
            <ng-container *ngIf="child.lifeCycle">
              <app-progress-bar [isSize]="false" [chartData]="child.lifeCycle.phases"></app-progress-bar>
            </ng-container>
          </div>

        </div>

      <!-- Children line -->
        <div class="child-element" [ngClass]="{'child-list-name': col.field == 'name'}" *ngIf="child.children?.length > 0 && child.expand">
          <ng-container *ngTemplateOutlet="tree; context:{node: child.children, columns: col, parent: child}"></ng-container>
        </div>
      </div>
  </div>
</ng-template>

<div *ngIf="isLoaded" class="pagination">
  <div class="pagination-rows">Rows per page:</div>
  <div class="pagination-counterRows">
    <div>{{ counterPerPage }}</div>
    <mat-icon *ngIf="true" svgIcon="arrowDropDown"></mat-icon>
  </div>
  <div class="pagination-counter">
    {{ counterNowPages }}
    <span>of</span>
    {{ conterAllPages }}
  </div>
  <div class="pagination-arrow">
    <mat-icon *ngIf="true" svgIcon="arrow-left"></mat-icon>
    <mat-icon *ngIf="true" svgIcon="arrow-right"></mat-icon>
  </div>
</div>


