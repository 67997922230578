import { Component, EventEmitter, OnInit, Input, HostListener, Output, SimpleChanges, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  imports: [
    MatIconModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  standalone: true,
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit, OnChanges {
  @Input() isDisabled: boolean = false;
  @Input() label: string = 'Label';
  @Input() value: string = '';
  @Output() valueChange = new EventEmitter<string>();

  selectedDate: Date | null = null;
  tempSelectedDate: Date | null = null;
  isCalendarVisible: boolean = false;

  currentYear: number = new Date().getFullYear();
  currentMonth: number = new Date().getMonth();
  daysInMonth: number[] = [];
  emptyDays: number[] = [];
  months: string[] = [
    'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
  ];
  weekdays: string[] = ['П', 'В', 'С', 'Ч', 'П', 'С', 'В'];
  years: number[] = [];
  startDay: number = 0;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.generateYears();
    this.generateCalendar(this.currentYear, this.currentMonth);

    this.matIconRegistry.addSvgIcon(
      'calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/calendar.svg')
    );

    this.matIconRegistry.addSvgIcon(
        'arrow-left',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-left-d.svg')
    );

    this.matIconRegistry.addSvgIcon(
        'arrow-right',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-right-d.svg')
    );

    if (this.value) {
      this.setDateFromString(this.value);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value'] && !changes['value'].isFirstChange()) {
      this.setDateFromString(changes['value'].currentValue);
    }
  }

  setDateFromString(dateString: string): void {
    this.tempSelectedDate = new Date(dateString.split('.').reverse().join('-'));
    this.generateCalendar(this.tempSelectedDate.getFullYear(), this.tempSelectedDate.getMonth());
    this.currentYear = this.tempSelectedDate.getFullYear();
    this.currentMonth = this.tempSelectedDate.getMonth();
    this.value = this.formatNumber(this.tempSelectedDate.getDate()) + '.' +
                 this.formatNumber(this.tempSelectedDate.getMonth() + 1) + '.' +
                 this.tempSelectedDate.getFullYear();
  }

  generateYears(): void {
    const startYear = this.currentYear - 50;
    const endYear = this.currentYear + 50;
    for (let year = startYear; year <= endYear; year++) {
      this.years.push(year);
    }
  }

  generateCalendar(year: number, month: number): void {
    const firstDay = (new Date(year, month, 1).getDay() + 6) % 7;
    const numDays = new Date(year, month + 1, 0).getDate();
    this.startDay = firstDay;
    this.emptyDays = Array(firstDay).fill(0);
    this.daysInMonth = Array(numDays).fill(0).map((_, i) => i + 1);
  }

  toggleCalendar(): void {
    this.isCalendarVisible = !this.isCalendarVisible;
  }

  previousMonth(): void {
    if (this.currentMonth === 0) {
      this.currentMonth = 11;
      this.currentYear--;
    } else {
      this.currentMonth--;
    }
    this.generateCalendar(this.currentYear, this.currentMonth);
  }

  nextMonth(): void {
    if (this.currentMonth === 11) {
      this.currentMonth = 0;
      this.currentYear++;
    } else {
      this.currentMonth++;
    }
    this.generateCalendar(this.currentYear, this.currentMonth);
  }

  selectDate(day: number): void {
    this.tempSelectedDate = new Date(this.currentYear, this.currentMonth, day);
    this.value = this.formatNumber(this.tempSelectedDate.getDate()) + '.' +
                 this.formatNumber(this.tempSelectedDate.getMonth() + 1) + '.' +
                 this.tempSelectedDate.getFullYear();
    this.valueChange.emit(this.value);
  }

  isSelected(day: number): boolean {
    return this.tempSelectedDate?.getDate() === day &&
           this.tempSelectedDate?.getMonth() === this.currentMonth &&
           this.tempSelectedDate?.getFullYear() === this.currentYear;
  }

  formatNumber(number: number): string {
    return number < 10 ? `0${number}` : `${number}`;
  }

  cancel(): void {
    this.tempSelectedDate = null;
    this.isCalendarVisible = false;
  }

  confirm(): void {
    if (this.tempSelectedDate) {
      this.selectedDate = this.tempSelectedDate;
      const day = this.formatNumber(this.selectedDate.getDate());
      const month = this.formatNumber(this.selectedDate.getMonth() + 1);
      const year = this.selectedDate.getFullYear();
      this.value = `${day}.${month}.${year}`;
      this.valueChange.emit(this.value);
      this.isCalendarVisible = false;
    }
  }

  onDateInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, '');

    if (value.length > 8) {
      value = value.substring(0, 8);
    }

    let formattedValue = '';
    if (value.length > 4) {
      formattedValue = `${value.substring(0, 2)}.${value.substring(2, 4)}.${value.substring(4)}`;
    } else if (value.length > 2) {
      formattedValue = `${value.substring(0, 2)}.${value.substring(2)}`;
    } else {
      formattedValue = value;
    }

    input.value = formattedValue;
    this.value = input.value;
    this.valueChange.emit(this.value);

    const [day, month, year] = this.value.split('.').map(num => parseInt(num, 10));
    if (!isNaN(day) && !isNaN(month) && !isNaN(year) && year.toString().length === 4) {
      const date = new Date(year, month - 1, day);
      if (date && (date.getMonth() + 1) === month) {
        this.tempSelectedDate = date;
        this.generateCalendar(year, month - 1);
        this.currentYear = year;
        this.currentMonth = month - 1;
      }
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.isCalendarVisible) {
      return;
    }
    const target = event.target as HTMLElement;
    if (!target.closest('.datepicker')) {
      this.isCalendarVisible = false;
    }
  }
}
