import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter, Subscription} from "rxjs";
import {ProjectApi} from "../../shared/api/project.api";
import {MainProjectLayoutService} from "../main-project-layout/main-project-layout.service";

@Component({
  selector: 'app-main-settings',
  templateUrl: './main-settings.component.html',
  styleUrl: './main-settings.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainSettingsComponent implements OnInit{
  id: any;
  projectData: any;
  isEditing: boolean = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private projectApi: ProjectApi,
    private changeDetection: ChangeDetectorRef,
    private mainService: MainProjectLayoutService
  ) {
    mainService.id.subscribe((res) => {
      this.id = res;
    })
  }
  ngOnInit() {
    this.getProjectById();
  }

  openSettingsEdit() {
    this.isEditing = !this.isEditing;
  }
  deleteProject() {
    this.projectApi.deleteProject(this.id)
      .subscribe(() => {
        this.router.navigate(['/'])
      })
  }
  getProjectById() {
    this.projectApi.getProjectById(this.id)
      .subscribe((res: any) => {
        this.projectData = res;
        this.changeDetection.markForCheck();
      })
  }
}
