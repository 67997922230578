import {Component, ViewChild} from "@angular/core";
import { MatDialogRef} from "@angular/material/dialog";

@Component({
  template: '',
})
export abstract class ModalBaseComponent {
  @ViewChild('characteristics')
  characteristicsEl: any;


    protected constructor(
     public dialogRef: MatDialogRef<any>,
  ) {
    this.dialogRef.disableClose = true;
  }
  closeDialog() {
    this.dialogRef.close();
  }
}

