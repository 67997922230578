import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit} from '@angular/core';
import {ModalBaseComponent} from "../../../../core/base/modal.base";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CommonModule} from "@angular/common";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormField, MatFormFieldModule, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput, MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {UserApi} from "../../../../shared/api/user.api";
import { MatDatepicker, MatDatepickerInput, MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatNativeDateModule, provideNativeDateAdapter } from '@angular/material/core';
import { ProjectApi } from '../../../../shared/api/project.api';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { TextareaComponent } from '../../../../core/ui-components/textarea/textarea.component';
import { DropDown, DropDownComponent } from '../../../../core/ui-components/dropdown/dropdown.component';
import { ButtonComponent } from '../../../../core/ui-components/button/button.component';
import { InputComponent } from '../../../../core/ui-components/input/input.component';
import { DomSanitizer } from '@angular/platform-browser';
import { DatepickerComponent } from '../../../../core/ui-components/datepicker/datepicker.component';
import moment from 'moment';
import {TaskTrackerApi} from "../../../../shared/api/task-tracker.api";
import {Router} from "@angular/router";

@Component({
  selector: 'app-edit-task',
  standalone: true,
  imports: [
    FormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    CommonModule,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    InputComponent,
    ButtonComponent,
    DropDownComponent,
    TextareaComponent,
    MatIconModule,
    DatepickerComponent,
  ],
  providers: [
    MatDatepickerModule,
    provideNativeDateAdapter()],

  templateUrl: './edit-task.component.html',
  styleUrl: './edit-task.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTaskComponent extends ModalBaseComponent implements OnInit{

  public isNameFolder: string;
  public isDescriptionFolder: string;
  public isDropListAdd: DropDown[] | null = [];
  public selectedId: number | null = null;
  public selectedUserId: any[];
  public selectedStatus: any[];
  public selectedPriority: any;
  public sendPriority: string;
  public startPlan: string;
  public endPlan: string;
  public parentDoc: any;
  public parentDocId: any;
  public isPlaceholder: string = 'Начните вводить название элементов или выберите из нижеперечисленных';

  public userList: DropDown[] = []
  public statusList: DropDown[] = [
    {id: 1, name: 'Запланировано', router: '',},
    {id: 2, name: 'В работе', router: '',},
    {id: 3, name: 'Выполнено', router: '',},
    {id: 4, name: 'Отклонено', router: '',},
  ]
  public priorityList: DropDown[] = [
    {id: 'LOW', name: 'Низкий', router: '',},
    {id: 'MEDIUM', name: 'Средний', router: '',},
    {id: 'HIGH', name: 'Высокий', router: '',},
  ]
  public roleList = ['Автор', 'Ответственный']

  constructor(
    override dialogRef: MatDialogRef<EditTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userApi: UserApi,
    private changeDetectionRef: ChangeDetectorRef,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
  ) {
    super(dialogRef);
  }

  ngOnInit() {
    this.isNameFolder = this.data?.info?.name;
    this.isDescriptionFolder = this.data?.info?.description;
    this.isDropListAdd = this.data.dropList;
    this.selectedId = this.data?.info?.id;
    this.selectedId = this.data?.info?.id;
    this.startPlan = this.data?.info?.startPlan;
    this.endPlan = this.data?.info?.endPlan;
    this.selectedStatus = [this.data?.info?.status];
    this.selectedUserId = [this.data?.info?.responsibleUser?.id];
    this.selectedPriority = [this.data?.info?.priority];
    this.sendPriority = this?.data?.info?.priority;
    this.parentDoc = this?.data?.info?.parentDoc?.name;
    this.parentDocId = this?.data?.info?.parentDoc?.id;
    this.updatePlaceholder();

    this.getUserList();
    this.matIconRegistry.addSvgIcon(
      'closeIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/close.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'linkIcon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/search-link.svg'
      )
    );
  }


  onSelectedIdChange(selectedId: number | null) {
    this.selectedId = selectedId;
    this.selectedStatus = [this.statusList.find(status => status.id === selectedId)?.name || ''];
    this.updatePlaceholder();
  }

  onUsersSelected(selectedId: number | null) {
    this.selectedUserId = [selectedId];
    this.updatePlaceholder();
  }

  onPriorityChange(selectedId: any) {
    this.sendPriority = selectedId;
    this.selectedPriority = [selectedId];
  }

  updatePlaceholder() {
    if (this.isDropListAdd && this.data.info.id) {
      const item = this.isDropListAdd.find(item => String(item.id) === String(this.data.info.id));
      if (item) {
        this.isPlaceholder = item.name;
      }
    }
  }

  getUserList() {
    this.userApi.getUserList()
      .subscribe((res: any) => {
        this.userList = res.content.map((user: any) => ({
          id: user.id,
          name: user?.firstName + ' ' + user?.lastName,
          router: '',
        }));
        this.changeDetectionRef.markForCheck();
      })
  }

  routeToDoc(){
    this.router.navigate([`project/${this.data.info.projectId}/work-material/document/${this.parentDocId}`])
  }


  saveTask() {
    if (moment(this.startPlan,'DD.MM.YYYY').format() !== 'Invalid date') {
      this.startPlan = moment(this.startPlan,'DD.MM.YYYY').format()
    }
    if (moment(this.endPlan,'DD.MM.YYYY').format() !== 'Invalid date') {
      this.endPlan = moment(this.endPlan,'DD.MM.YYYY').format()
    }
    let task = {
      id: this.data.info.id,
      parentProjectId: this.data.parentProjectId,
      parentTaskId: this.data.parentTaskId,
      startPlan: this.startPlan,
      endPlan: this.endPlan,
      creator: {id: this.data?.info?.creator?.id},
      responsibleUser: {id: this.selectedUserId[0]},
      name: this.isNameFolder,
      description: this.isDescriptionFolder,
      status: this.selectedStatus[0],
      parentCommentId: this.data?.info?.parentCommentId,
      priority: this.sendPriority
    }
    this.dialogRef.close(task)
  }
}
