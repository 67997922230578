import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter, Subscription} from "rxjs";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, OnDestroy {
  routerSubscription: Subscription = new Subscription();
  isMenuItem = false;
  activeComponent = ''
  url = ''
  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
   this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((_:any) => {
        const data: any = this.activatedRoute.snapshot.firstChild?.data;
        const activeComponentData:any = this.activatedRoute.snapshot.firstChild?.firstChild?.firstChild?.data
        const url: any = this.activatedRoute.snapshot.firstChild?.params
       this.url = url.id
       this.isMenuItem = data.isMenuItem
        if( activeComponentData) {
          this.activeComponent = activeComponentData.activeComponent
        }
      });
  }
  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
  routeTo(url: string) {
    console.log(this.url)
   this.router.navigate(['project/'+this.url+url])
  }

}
