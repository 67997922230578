import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit} from '@angular/core';
import {ModalBaseComponent} from "../../../../core/base/modal.base";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CommonModule} from "@angular/common";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormField, MatFormFieldModule, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput, MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {UserApi} from "../../../../shared/api/user.api";
import { MatDatepicker, MatDatepickerInput, MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatNativeDateModule, provideNativeDateAdapter } from '@angular/material/core';
import { ProjectApi } from '../../../../shared/api/project.api';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { TextareaComponent } from '../../../../core/ui-components/textarea/textarea.component';
import { DropDown, DropDownComponent } from '../../../../core/ui-components/dropdown/dropdown.component';
import { ButtonComponent } from '../../../../core/ui-components/button/button.component';
import { InputComponent } from '../../../../core/ui-components/input/input.component';
import { DomSanitizer } from '@angular/platform-browser';
import { DatepickerComponent } from '../../../../core/ui-components/datepicker/datepicker.component';
import moment from 'moment';

@Component({
  selector: 'app-add-task',
  standalone: true,
  imports: [
    FormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    CommonModule,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    InputComponent,
    ButtonComponent,
    DropDownComponent,
    TextareaComponent,
    MatIconModule,
    DatepickerComponent,
  ],
  providers: [
    MatDatepickerModule,
    provideNativeDateAdapter()],

  templateUrl: './add-task.component.html',
  styleUrl: './add-task.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddTaskComponent extends ModalBaseComponent implements OnInit{

  public isNameFolder: string;
  public isDescriptionFolder: string;
  public isDropListAdd: DropDown[] | null = [];
  public selectedId: number | null = null;
  public selectedUserId: number | null = null;
  public selectedStatus: DropDown | null = null
  public selectedPriority: DropDown | null = null
  public startPlan: string;
  public endPlan: string;
  public sendPriority: string;
  public isPlaceholder: string = 'Начните вводить название элементов или выберите из нижеперечисленных';

  public userList: DropDown[] = []
  public userInfo: any
  public statusList: DropDown[] = [
    {id: 1, name: 'Запланировано', router: '',},
    {id: 2, name: 'В работе', router: '',},
    {id: 3, name: 'Выполнено', router: '',},
    {id: 4, name: 'Отклонено', router: '',},
  ]
  public priorityList: DropDown[] = [
    {id: 1, name: 'Низкий', router: '',},
    {id: 2, name: 'Средний', router: '',},
    {id: 3, name: 'Высокий', router: '',},
  ]
  public roleList = ['Автор', 'Ответственный']

  constructor(
    override dialogRef: MatDialogRef<AddTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userApi: UserApi,
    private changeDetectionRef: ChangeDetectorRef,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    super(dialogRef);
  }

  ngOnInit() {
   console.log(this.data)
   console.log(this.data.dropList)
   this.isNameFolder = '';
   this.isDescriptionFolder = '';
   this.isDropListAdd = this.data.dropList;
   this.selectedId = this.data?.info?.id;
   this.startPlan = '';
   this.endPlan = '';
   this.updatePlaceholder();

   this.getUserList();
   this.getUserInfo();
   this.matIconRegistry.addSvgIcon(
    'closeIcon',
    this.domSanitizer.bypassSecurityTrustResourceUrl(
      'assets/icons/close.svg'
    )
  );
  }

  onSelectedIdChange(selectedId: number | null) {
    console.log(selectedId)
    this.selectedId = selectedId;
    this.selectedStatus = this.statusList.find(status => status.id === selectedId) || null;
    this.updatePlaceholder();
  }

  onUsersSelected(selectedId: number | null) {
    console.log(selectedId)
    this.selectedUserId = selectedId;
    this.updatePlaceholder();
  }

  onPriorityChange(selectedId: number | null) {
    console.log(selectedId)
    this.selectedId = selectedId;
    this.selectedPriority = this.priorityList.find(priority => priority.id === selectedId) || null;
    if (this.selectedPriority?.name == 'Низкий'){
      this.sendPriority = 'LOW'
    }
    if (this.selectedPriority?.name == 'Средний'){
      this.sendPriority = 'MEDIUM'
    }
    if (this.selectedPriority?.name == 'Высокий'){
      this.sendPriority = 'HIGH'
    }
  }

  updatePlaceholder() {
    if (this.isDropListAdd && this.data.info.id) {
      const item = this.isDropListAdd.find(item => String(item.id) === String(this.data.info.id));
      if (item) {
        this.isPlaceholder = item.name;
      }
    }
  }

  getUserList() {
    this.userApi.getUserList()
      .subscribe((res: any) => {
        console.log(res.content)
       this.userList = res.content.map((user: any) => ({
        id: user.id,
        name: user?.firstName + ' ' + user?.lastName,
        router: '',
      }));
        this.changeDetectionRef.markForCheck();
      })
  }
  getUserInfo(){
    this.userApi.getUserInfo()
    .subscribe((res: any) => {
     this.userInfo = res
      this.changeDetectionRef.markForCheck();
    })
  }
  saveTask() {
    if (moment(this.startPlan,'DD.MM.YYYY').format() !== 'Invalid date') {
      this.startPlan = moment(this.startPlan,'DD.MM.YYYY').format()
    }
    if (moment(this.endPlan,'DD.MM.YYYY').format() !== 'Invalid date') {
      this.endPlan = moment(this.endPlan,'DD.MM.YYYY').format()
    }
    let task = {
      parentCommentId: null,
      parentProjectId: this.data.parentProjectId,
      parentTaskId: this.data.info.id,
      startPlan: this.startPlan,
      endPlan: this.endPlan,
      creator: {id: this.userInfo?.id},
      responsibleUser: {id: this.selectedUserId},
      status: this.selectedStatus?.name,
      name: this.isNameFolder,
      description: this.isDescriptionFolder,
      priority: this.sendPriority,
      parentDocId: null
    };

    if (this.data.fromTaskTracker) {
      task.parentTaskId = null;
      task.parentCommentId =  this.data.info.id;
      task.parentDocId = this.data.info.documentId;
    }
  console.log(task)
    this.dialogRef.close(task)

    console.log(this.isNameFolder, 'isNameFolder')
  }
}
