import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})

export class TaskTrackerApi {
 apiUrl = '/skrndapp'
 headers: HttpHeaders;
 token: string|null;
  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('view_auth_token');
    this.headers = new HttpHeaders().append('Authorization', `Basic ${this.token}`)
  }

  getTaskTrackerList() {
   return this.http.get(`${this.apiUrl}/tasks`, {
     params: {
       page: 0,
       size: 10,
       sortBy: 'id',
       sortDirection: 'ASC'
     },
     headers: this.headers
   })
  }
  getTaskTrackerByParentId(parentTaskId:any) {
    return this.http.get(`${this.apiUrl}/task`, {
      params: {
        parentTaskId: parentTaskId,
      },
      headers: this.headers
    })
   }
  getTaskTrackerById(id: any) {
   return this.http.get(`${this.apiUrl}/task/${id}`, {
    params: {
      parentTaskId: id,
    },
     headers: this.headers
   })
  }

  getTaskTrackerByProjectId(projectId: any) {
    return this.http.get(`${this.apiUrl}/tasksByProjectId/${projectId}`, {
     params: {
       projectId: projectId,
     },
      headers: this.headers
    })
   }
  // getTasksTrackersListById(id:any, parentType: any) {
  //   return this.http.get(`${this.apiUrl}/task/sort`, {
  //     params: {
  //       parentId: id,
  //       parentType: parentType
  //     },
  //     headers: this.headers
  //   })
  // }
  saveTaskTracker(data) {
    return this.http.post(`${this.apiUrl}/task`, data , {
      headers: this.headers
    })
  }
  saveSortTaskTracker(data) {
    return this.http.post(`${this.apiUrl}/spaces`, data , {
      headers: this.headers
    })
  }
  deleteTaskTrackerById(id: any) {
    return this.http.delete(`${this.apiUrl}/task/${id}`, {
      headers: this.headers
    })
   }

}
