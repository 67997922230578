import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MainSettingsModule} from "../../main-settings.module";
import {generateProjectMock} from "./project";
import {CommonModule} from "@angular/common";
import {RouterLink, RouterLinkActive} from "@angular/router";

@Component({
  selector: 'app-add-project',
  standalone: true,
  imports: [
    MainSettingsModule,
    CommonModule,
    RouterLink,
    RouterLinkActive
  ],
  templateUrl: './add-project.component.html',
  styleUrl: './add-project.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddProjectComponent implements OnInit{
  projectMock
  constructor(private changeDetectionRef: ChangeDetectorRef) {
  }
  ngOnInit() {
    this.getProjectMock();
  }

  getProjectMock() {
    this.projectMock = generateProjectMock()
    this.changeDetectionRef.markForCheck();
  }
}
