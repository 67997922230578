import {APP_INITIALIZER, NgModule} from "@angular/core";
import {AppComponent} from "./app.component";
import {AppRoutingModule} from "./app-routing.module";
import {BrowserModule} from "@angular/platform-browser";
import {MainLayoutComponent} from "./core/main-layout/main-layout.component";
import {provideAnimations} from "@angular/platform-browser/animations";
import {HttpClientModule} from "@angular/common/http";
import {CommonModule} from "@angular/common";
import {KeycloakAngularModule, KeycloakService} from "keycloak-angular";
import {CommonService} from "./shared/services/common-service";
import {initializeKeycloak} from "./utility/app.init";
import {NotificationModule} from "./core/ui-components/notification/notification.module";
import { ButtonComponent } from "./core/ui-components/button/button.component";
import { InputComponent } from "./core/ui-components/input/input.component";
import { DatepickerComponent } from './core/ui-components/datepicker/datepicker.component';
import { DropDownComponent } from "./core/ui-components/dropdown/dropdown.component";
import { TextareaComponent } from "./core/ui-components/textarea/textarea.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchComponent } from "./core/ui-components/search/search.component";
import { NgxGanttModule } from '@worktile/gantt';
// import { WorkMaterialComponent } from './pages/work-material/work-material.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
      AppRoutingModule,
      BrowserModule,
      FormsModule,
      ReactiveFormsModule,
      ButtonComponent,
      InputComponent,
      DatepickerComponent,
      DropDownComponent,
      TextareaComponent,
      SearchComponent,
      MainLayoutComponent,
      HttpClientModule,
      CommonModule,
      KeycloakAngularModule,
      NotificationModule,
      NgxGanttModule,
      // WorkMaterialComponent,
  ],
  bootstrap: [AppComponent],
  providers: [
    provideAnimations(),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, CommonService],
    },
  ]
})
export class AppModule {}
