<div class="view-file-container">
  <div class="header">
    <div class="title">
      <span class="text-18 text-600">Просмотр {{data.type == 'document' ? 'изображения' : 'вложения'}}</span>
    </div>
    <mat-icon
      class="item-close"
      *ngIf="true"
      svgIcon="closeIcon"
      (click)="closeDialog()"
    ></mat-icon>
  </div>
  <div class="view-file-container-inside-block" *ngIf="openImage" >
    <img [src]="image">
  </div>
  <div class="iframe-container" *ngIf="openDocument" >
    <iframe [src]="image" class="iframe-class" width="100%" ></iframe>
  </div>
</div>
