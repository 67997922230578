import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {BooleanPipe} from "./boolean.pipe";

@NgModule({
  declarations: [BooleanPipe],
  exports: [BooleanPipe],
  imports: [CommonModule],
})
export class BooleanModule {
}
